import React, { useState } from 'react';

import { FormGroup } from "@mui/material";
import { StationPanel, StationPanelProps} from "components/StationPanel/StationPanel";
import styles from "./FocusMode.module.css"
import { Toggle } from "components/Toggle/Toggle";


interface FocusModeProps {
  stationPanelProps: StationPanelProps;
  onExitPress: () => void;
  diagramType: string
}

export const FocusMode = (props: FocusModeProps) => {
  const [colorScheme, setColorScheme] = useState(true)
  const [showLabels, setShowLabels] = useState(true)
  const [showHorizons, setShowHorizons] = useState(true)
  const [zoomMode, setZoomMode] = useState(true)

  const switchControls = () => {
    switch (props.diagramType) {
      case "polargram":
      case "radargram":
        return (
          <FormGroup className={styles.formGroup}>
            <Toggle 
              text={"Color scheme"}
              checked={colorScheme}
              onChange={(checked) => setColorScheme(checked)}
              labelPlacement={"start"}            
            />
            <Toggle 
              text={"Show labels"}
              checked={showLabels}
              onChange={(checked) => setShowLabels(checked)}
              labelPlacement={"start"}            
            />
            <Toggle 
              text={"Show horizons"}
              checked={showHorizons}
              onChange={(checked) => setShowHorizons(checked)}
              labelPlacement={"start"}            
            />
    
          </FormGroup>
        )
      case "coreSample": 
        // return (
        //   <FormGroup className={styles.formGroup}>
        //      <Toggle 
        //       text={"Zoom mode"}
        //       checked={zoomMode}
        //       onChange={(checked) => setZoomMode(checked)}
        //       labelPlacement={"start"}            
        //     />
        //   </FormGroup>
        // )
      default:
        return null
    }

  }

  return (
    <div className={styles.container} >
      <div className={styles.content}>
        <div className={styles.stationPanelContainer}>
          <StationPanel
            {...props.stationPanelProps}
            hideHorizons={!showHorizons}
            hideLabels={!showLabels}
          />
        </div>
        
        <div className={styles.controlContainer}>
          <div className={styles.exitContainer} >
          <button 
            className="primary-button"
            onClick={props.onExitPress} >Exit</button>
          </div>
         
          {switchControls()}
        </div>
      </div>
    </div>
  )
}