export const HangingWallIcon = ({cx, cy, highlight} : {cx: number, cy: number, highlight: boolean}) => {
  const scale = highlight ? 1.3 : 1

  return (
    <svg 
      x={cx - 3.5 * scale} y={cy - 4.5 * scale} width={7 * scale} height={9 * scale} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg"
    transform={`scale(${highlight ? 1.3 : 1})`} transform-origin={`${cx} ${cy}`}
    >
      <path  stroke="white" strokeWidth={highlight ? 2 : 0} d="M0.5 6.86603C-0.166667 6.48113 -0.166667 5.51888 0.5 5.13397L8 0.803848C8.66667 0.418948 9.5 0.900074 9.5 1.66987L9.5 10.3301C9.5 11.0999 8.66667 11.5811 8 11.1962L0.5 6.86603Z" fill="#F7C368"/>
    </svg>
  )

}