import React, { useContext, useEffect, useRef, useState } from 'react';
import 'styles/global.css'
import styles from "./LabelModal.module.css"
import { useLabelModal } from "context/LabelModalProvider";
import { StationLabelStore } from "models/StationLabelStore";
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import Delete from "images/Delete.svg"
import { LabelIcon, LabelType } from "components/types/LabelType";
import { LabelTypeDropdown } from "components/LabelTypeDropdown/LabelTypeDropdown";
import { getAzimuthFromRadargram } from "utils/Conversion";
import Edit from "images/Edit.svg"
import { CoordToString } from "utils/ToString";
import { saveLabelsToStorage } from "utils/SaveLabelInfo";


interface LabelModalProps {
  top: number;
  left: number;
  defaulValue?: string;
  setLabelModalVisible: (visible: boolean) => void;
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const LabelModal = (props: LabelModalProps) => {
  const { setModalVisibility, tempCoords, setTempCoords, editLabel, setEditLabel, editingCoordIndex, setEditingCoordIndex, cancelLabelCreation } = useLabelModal();
  
  const editMode = editLabel != null
  
  const [labelName, setLabelName] = useState("")
  const [currentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})
  const [allLables, setAllLabels] = useLocalStorage<StationLabelStore>("stationLabelStore", {});

  const [stationLabels, setStationLabels] = useState(allLables[currentBorehole.project]?.[currentBorehole.borehole] || {});
  const [selectedCoordId, setSelectedCoordId, removeSelectedCoordId] = useSessionStorage('selectedCoordId', "")

  const [labelType, setLabelType] = useState<LabelType | undefined>();
  const [enableCreate, setEnableCreate] = useState(editMode);

  const { top, left, defaulValue, setLabelModalVisible, onMouseDown } = props

  const onCancelClick = () => {
    cancelLabelCreation()
  }

  const onCreateClick = () => {
    if (!labelType || labelName.length <= 0) return;

    setStationLabels((prev) => {
      if (tempCoords.panelId >= 0 && tempCoords.coordinates.length > 0) {
        const newState = { ...prev }

        // Ensure the station exists before updating
        const stationKey = `station${tempCoords.panelId}`;
        if (!newState[stationKey]) {
          newState[stationKey] = {
            stationId: tempCoords.panelId,
            labels: []
          };
        }

        // Create a new points array to maintain immutability
        newState[stationKey].labels = [
          ...newState[stationKey].labels,
          {
            id: crypto.randomUUID(),
            name: labelName,
            coordinates: tempCoords.coordinates.map(coord => ({
              ...coord,
              hide: false,
              id: crypto.randomUUID(),
              stationId: tempCoords.panelId,
              azimuth: getAzimuthFromRadargram(coord.x, coord.y),
            })),
            type: labelType
          }
        ];

        setAllLabels((prev) => {
          return saveLabelsToStorage(currentBorehole.project, currentBorehole.borehole, prev, newState)
        })
        setTempCoords({ panelId: -1, coordinates: [], labelType: "unk" })
        return newState
      }
      return prev;
    })

    cancelLabelCreation()
  }

  const onSaveClick = () => {
    if (editLabel == null || !labelType || labelName.length <= 0) return;

    setStationLabels((prev) => {
      const newState = { ...prev }
      newState[editLabel.stationKey].labels[editLabel.labelIndex].name = labelName
      newState[editLabel.stationKey].labels[editLabel.labelIndex].coordinates = tempCoords.coordinates
      newState[editLabel.stationKey].labels[editLabel.labelIndex].type = labelType

      setAllLabels((prev) => {
        return saveLabelsToStorage(currentBorehole.project, currentBorehole.borehole, prev, newState)
      })
      return newState
    })
    cancelLabelCreation()

  }

  const onDeleteClick = () => {
    if (editLabel == null) return;

    setStationLabels((prev) => {
      const newState = { ...prev }
      newState[editLabel.stationKey].labels = newState[editLabel.stationKey].labels.filter((_, index) => index !== editLabel.labelIndex);

      if (newState[editLabel.stationKey].labels.length === 0) {
        delete newState[editLabel.stationKey];
      }
      setAllLabels((prev) => {
        return saveLabelsToStorage(currentBorehole.project, currentBorehole.borehole, prev, newState)
      })
      return newState
    })

    cancelLabelCreation()
  }

  useEffect(() => {
    if (editLabel != null) {
      const editingLabel = stationLabels[editLabel.stationKey].labels[editLabel.labelIndex]

      setLabelName(editingLabel.name);
      setLabelType(editingLabel.type);
      setTempCoords({ panelId: stationLabels[editLabel.stationKey].stationId, coordinates: editingLabel.coordinates, labelType: editingLabel.type })
    }

  }, [editLabel])

  const onEditCoordClick = (i: number) => {
    if (editingCoordIndex >= 0) {
      setEditingCoordIndex(-1)
    } else {
      setEditingCoordIndex(i)
    }
  }

  return (
    <div className={styles.container}
      onMouseDown={onMouseDown}
      style={{ top: top + 10, left: left + 10 }}>

      <span className={styles.inputTitle}>Coordinates</span>
      {
        tempCoords.coordinates.map((c, i) => {
          return (
            <div 
              className={`${styles.inputField} ${editingCoordIndex === i ? styles.editing : ""}`}
              key={`temp-coord${i}`}>
              <span className={styles.coordText}>
                {CoordToString(c)}
              </span>
              <img 
                src={Edit}
                alt="Clickable Icon"
                style={{cursor: "pointer"}}
                onClick={() => onEditCoordClick(i)}
              />
          </div>
          )
        })
      }

      <LabelTypeDropdown 
        selectedType={labelType}
        onOptionSelect={(selectedType) => {
          const newLabel = tempCoords
          newLabel.labelType = selectedType

          setLabelType(selectedType)
          setEnableCreate(() => { return selectedType != undefined && labelName.length > 0})
          setTempCoords(newLabel)
        }}/>

      <span className={styles.inputTitle}>Label name</span>
      <input className={styles.inputField}
        value={labelName}
        onChange={(e) => {
          setLabelName(e.target.value)
          setEnableCreate(() => { return labelType != undefined && e.target.value.length > 0})
        }} />

      <div className={styles.actionRow}>
        <button
          className={styles.cancelButton}
          onClick={onCancelClick}>
          Cancel
        </button>
        <button
          disabled={!enableCreate}
          className={styles.createButton}
          onClick={editMode ? onSaveClick : onCreateClick}>
          {editMode ? "Save" : "Create"}
        </button>

      </div>

      {
        editMode &&
        <div>
          <button
            className={styles.deleteButton}
            onClick={onDeleteClick}>
            Delete <img src={Delete} />
          </button>

        </div>
      }
    </div>
  )
}