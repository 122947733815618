import { ComparisonMode } from "components/ComparisonMode/ComparisonMode";
import { StationPanel } from "components/StationPanel/StationPanel";
import { useMousePosition } from "context/MousePositionContext";
import { useState, useEffect, useRef, useContext, useReducer } from 'react';
import styles from "./MainContent.module.css"
import { FocusMode } from "components/FocusMode/FocusMode";
import { LabelModal } from "components/CreateLabelModal/LabelModal";
import { PaginationPage } from "components/Pagination/PaginationPage";
import { ImageVisibilityProvider } from "context/ImageVisibilityContext";
import { Grid, SectionRenderedParams } from 'react-virtualized';
import { useLabelModal } from "context/LabelModalProvider";
import { useDebounceCallback, useSessionStorage, useWindowSize } from "usehooks-ts";
import { ViewMode } from "components/types/ViewMode";
import { DiagramType } from "components/types/DiagramType";
import { Revolution } from "components/types/Revolution";
import { Borehole, Depths, Project } from "hooks/useProjectData";

interface MainContentProps {

}

export const MainContent = (props: MainContentProps) => {
  const [centerStationId, setCenterStationId, removeCenterStationId] = useSessionStorage('centerStationId', -1)

  const {modalVisibility, setModalVisibility, modalPos, setModalPos, cancelLabelCreation} = useLabelModal();
  
  const [viewMode, setViewMode] = useState<ViewMode>("default")
  const [selectedPanels, setSelectedPanels] = useState<number[]>([]);
  const [focusedDiagram, setFocusedDiagram] = useState<{panelId: number, diagramType: DiagramType}>({panelId: -1, diagramType: "none"});
  const isFocusModeRef = useRef<boolean>(false)
  const [isDragging, setIsDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })

  const [visiblePageStart, setVisiblePageStart] = useState(0)
  const [visiblePageStop, setVisiblePageStop] = useState(0)
  const [numPages, setNumPages] = useState(0)

  const { hoveredImage } = useMousePosition();
  const [revolutions, setRevolutions] = useState<Revolution[]>([]);
  const [ataVisibles, setAtaVisible] = useState<boolean[]>([]);

  const contentRef = useRef<HTMLDivElement>(null);

  const [allowForceScroll, setAllowForceScroll] = useState(true)
  const [secondPanelRevolution, setSecondPanelRevolution] = useState<Revolution>({runId: "", revId: ""})
  const [scrollToIndex, setScrollToIndex] = useState(0);

  const [project] = useSessionStorage<Project>('project-sokoman', {})

  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})

  const [boreholeDepthKeys, setBoreholeDepthKeys] = useState<string[]>([])
  const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize()
  
  const [_, setState] = useState(0);

  const forceReRender = () => {
    setState(prev => prev + 1); // Force a state update
  };

  useEffect(() => {
    try {
      const numDepths = Object.keys(project[currentBorehole.project].boreholes[currentBorehole.borehole].depths).length
      const boreholeDepths = project[currentBorehole.project].boreholes[currentBorehole.borehole].depths
      setNumPages(numDepths)
      
      setBoreholeDepthKeys(Object.keys(boreholeDepths))
  
      const revolutionData = []
      for (const [depthKey, depth] of Object.entries(boreholeDepths)) {
        const firstRun = Object.keys(depth.runs)[0];
        revolutionData.push({runId: firstRun, revId: Object.keys(depth.runs[firstRun].revs)[0]})
      };
      setRevolutions(revolutionData)
      setAtaVisible(Array(numDepths).fill(true))
    } catch (e) {
      console.log(project)
    }
  }, [currentBorehole])


  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true)
    setDragOffset({ x: e.clientX - modalPos.left, y: e.clientY - modalPos.top })
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      setModalPos({ top: e.clientY - dragOffset.y, left: e.clientX - dragOffset.x })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])
  
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Space") {
      handleSpaceKeyDown();
    } else if (e.code === "ArrowRight" || e.code === "ArrowLeft") {
      e.preventDefault();
      handleArrowKeyDown(e.code);
      e.stopPropagation()
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [viewMode, selectedPanels, hoveredImage, centerStationId, setCenterStationId, visiblePageStart]);

  useEffect(() => {
    console.log("VISIBILITY CHANGED", ataVisibles[0])
  }, [ataVisibles])
  const handleSpaceKeyDown = () => {
    if (selectedPanels.length === 1 || selectedPanels.length === 2) {
      setViewMode("comparison")
      cancelLabelCreation()
    } else if (hoveredImage.diagramType !== "none") {  
      setViewMode("focus")
      isFocusModeRef.current = true
      setFocusedDiagram(hoveredImage)
      cancelLabelCreation()
    }
  }

  /******************************** Scroll Behavior *****************************/

  const isScrolling = useRef(false); // Prevent overlapping scroll events.

  const smoothScrollTo = (start: number, end: number, duration: number) => {
    const startTime = performance.now();

    const step = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const ease = progress < 0.5
        ? 2 * progress * progress
        : -1 + (4 - 2 * progress) * progress; // EaseInOutQuad
      const newScrollLeft = start + (end - start) * ease;

      panelGridRef.current?.scrollToPosition({ scrollLeft: newScrollLeft, scrollTop: 0 });

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        isScrolling.current = false; // Reset the scroll lock.
      }
    };

    requestAnimationFrame(step);
  };

  const handleOnPanelClick = (panelId: number) => {
    setSelectedPanels(prevSelected => {
      // Check if the panelId is already selected
      if (prevSelected.includes(panelId)) {
        // Remove the panelId if it exists
        return prevSelected.filter(id => id !== panelId);
      } else {
        // Add the new panelId
        const newSelected = [...prevSelected, panelId];
        // If the size exceeds 2, remove the oldest one
        return newSelected.length > 2 ? newSelected.slice(1) : newSelected;
      }
    });
  }

  const handleAtaVisibility = (value: boolean, dataIndex: number) => {
    setAtaVisible((prev) => {
      const newState = [...prev]
      newState[dataIndex] = value;
      return newState
    })
    console.log("Recompute", dataIndex)
    // panelGridRef.current?.recomputeGridSize({columnIndex: dataIndex})
  }


  const renderStationPanel = (panelId: number, dataIndex: number) => {
    return (
      <StationPanel
        revolution={revolutions[dataIndex]}
        onRevolutionChange={(value) => onRevolutionChange(dataIndex, value)}
        onPanelClick={() => handleOnPanelClick(panelId)}
        key={`station-panel-${dataIndex}`}
        panelId={dataIndex}
        viewMode={viewMode}
        outlinePanel={selectedPanels.includes(panelId)}
        depth={parseInt(boreholeDepthKeys[panelId])}
        colorScheme={true}
        ataVisible={ataVisibles[dataIndex]}
        setAtaVisible={(value) => handleAtaVisibility(value, dataIndex)}
      />
    )
  }

  const stationPanelCellRenderer = ({ columnIndex, key, style }: any) => (
    <div key={key} style={style}>
      {renderStationPanel(columnIndex, columnIndex)}
    </div>
  );

  const handleStationOnScroll = (params: SectionRenderedParams) => {
    const {columnStartIndex, columnStopIndex} = params;
    setVisiblePageStart(columnStartIndex)
    setVisiblePageStop(columnStopIndex)
  }

  const stationPanelCellWidth = (panelIndex: number) => {
    const narrow = false//!ataVisibles[panelIndex]
    const root = document.documentElement;
    const headerH = parseFloat(getComputedStyle(root).getPropertyValue('--header').trim());
    const pagingH = parseFloat(getComputedStyle(root).getPropertyValue('--paging-height').trim());
    const stationMetricsH = parseFloat(getComputedStyle(root).getPropertyValue('--station-metrics-height').trim());
    const stationTitleH = parseFloat(getComputedStyle(root).getPropertyValue('--station-title-height').trim());
    const coreSampleH = parseFloat(getComputedStyle(root).getPropertyValue('--core-sample-height').trim());
    const panelHeight = windowHeight - headerH - pagingH -25;

    const radarWidth = (panelHeight - stationMetricsH - stationTitleH - coreSampleH - 8) / 2
    let width;

    if (narrow) {
      const ataNarrow = parseFloat(getComputedStyle(root).getPropertyValue('--ata-width-narrow').trim());
      width = radarWidth + ataNarrow + 2
    } else {
      width = radarWidth * 1.33
    }

    return width; 
  };

  const panelGridRef = useRef<Grid>(null);

  const onRevolutionChange = (panelId: number, value: Revolution) => {
    console.log(value)
    setRevolutions(prev => {
      const newArray = [...prev];
      newArray[panelId] = value;
      return newArray
    })
  }

  const handleArrowKeyDown = (key: string) => {
    if (panelGridRef.current) {
      const { columnCount, width } = panelGridRef.current.props;
      const { scrollLeft } = panelGridRef.current.state;

      const accWidth: number[] = []
      let currentColumnIndex = 0
      let foundCurrentColumnIndex = false
      // Calculate maximum scrollable distance
      const sumWidth = ataVisibles.reduce((acc, value, index) => {
        const curAcc = acc + stationPanelCellWidth(index) + 1
        if (!foundCurrentColumnIndex && curAcc > scrollLeft) {
          currentColumnIndex = index;

          foundCurrentColumnIndex = true
        }
        accWidth.push(curAcc)
        return curAcc
      }, 0);
      const maxScrollLeft = sumWidth - width + 16;

      // Determine the currently visible column index
      // const currentColumnIndex = Math.floor(scrollLeft / stationPanelCellWidth());
      currentColumnIndex = visiblePageStart
      let targetColumnIndex = currentColumnIndex;

      // Adjust target column based on arrow key
      if (key === 'ArrowRight') {
        targetColumnIndex = Math.min(currentColumnIndex + 1, columnCount - 1);
      } else if (key === 'ArrowLeft') {
        targetColumnIndex = Math.max(currentColumnIndex - 1, 0);
      } else {
        return; // Ignore other keys
      }

      // Calculate the target scroll position
      const newScrollLeft = Math.min(targetColumnIndex === 0 ? 0 : accWidth[targetColumnIndex - 1], maxScrollLeft);

      // Smooth scroll to the target position
      smoothScrollTo(scrollLeft, newScrollLeft, 300);
    }
  };

  const contentWidth = windowWidth - 230 - 16;

  const handleWheel = (event: React.WheelEvent) => {
    if (panelGridRef.current && event.deltaY !== 0 && event.deltaX === 0) {
      const { scrollLeft } = panelGridRef.current.state;

      const sumWidth = ataVisibles.reduce((acc, value, index) => {
        const curAcc = acc + stationPanelCellWidth(index)
        return curAcc
      }, 0);

      const maxScrollLeft = sumWidth - contentWidth + 16
      let newScrollLeft = scrollLeft + event.deltaY

      newScrollLeft = Math.max(0, Math.min(newScrollLeft, maxScrollLeft));
      console.log(newScrollLeft)
      
      smoothScrollTo(scrollLeft, newScrollLeft, 200);

      event.stopPropagation();
    }
  };

  const defaultMode = () => {
    return (
      <div
        onWheel={handleWheel}
        className={styles.scrollableArea}
        style={{visibility: viewMode === "default" ? "visible" : "hidden"}}>
       
       <Grid
          ref={panelGridRef}
          columnCount={numPages} 
          width={contentWidth} 
          height={ windowHeight  - 110 - 5} // remove height for the upper components and scrolling bar
          columnWidth={(params) => stationPanelCellWidth(params.index)}
          rowHeight={windowHeight  - 110 - 20} // remove height for the upper components and small padding
          cellRenderer={stationPanelCellRenderer} 
          onSectionRendered={handleStationOnScroll}
          style={{outline: "none"}}
          onScroll={() => setAllowForceScroll(true)}
          rowCount={1} 
          scrollToAlignment="start"
        />
      </div>
    )
  }

  const focusMode = () => {
    const stationPanelProps = {
      setLabelModalVisible: (visible: boolean) => setModalVisibility(visible),
      setLabelModalPos: (top: number, left: number) => {
          setModalPos({ top: top, left: left })
      },
      panelId: focusedDiagram.panelId,
      hoveredImage: hoveredImage,
      revolution: revolutions[focusedDiagram.panelId],
      onRevolutionChange: (value: Revolution) => onRevolutionChange(focusedDiagram.panelId, value),
      focusedDiagram: focusedDiagram,
      viewMode: "focus" as ViewMode,
      outlinePanel: false,
      depth: parseFloat(boreholeDepthKeys[focusedDiagram.panelId]),
      colorScheme: true,
      ataVisible: ataVisibles[focusedDiagram.panelId],
      setAtaVisible: (value: boolean) => handleAtaVisibility(value, focusedDiagram.panelId)
    };
    return (
      <div className={styles.focusModeContainer} >
        <FocusMode
          stationPanelProps={stationPanelProps}
          diagramType={focusedDiagram.diagramType}
          onExitPress={() => {
            setViewMode("default")
            isFocusModeRef.current = false
            cancelLabelCreation()
          }}
        />
      </div>
    )
  }
  const exitComparisonMode = () => {
    // if (selectedPanels.length === 2 && selectedPanels[0] === selectedPanels[1]) {
    //   setSelectedPanels(() => {
    //     selectedPanels.pop();
    //     return selectedPanels
    //   })
    // }
    setSelectedPanels([])
    setViewMode("default")
    cancelLabelCreation()
  }

  const comparisonMode = () => {
    const sameStation = selectedPanels.length === 2 && selectedPanels[0] === selectedPanels[1] 
    const dataIds = selectedPanels.map((panelIndex: number, i) => {
      const secondPanelWithSameStation  = sameStation && i === 1
      return ({
        setLabelModalVisible: (visible: boolean) => setModalVisibility(visible),
        setLabelModalPos: (top: number, left: number) => {
            setModalPos({ top: top, left: left })
        },
        panelId: panelIndex,
        hoveredImage: hoveredImage,
        revolution: secondPanelWithSameStation ? secondPanelRevolution : revolutions[panelIndex],
        onRevolutionChange: (value: Revolution) => {
          secondPanelWithSameStation ? setSecondPanelRevolution(value) : onRevolutionChange(panelIndex, value)
        },
        focusedDiagram: focusedDiagram,
        viewMode: "comparison" as ViewMode,
        outlinePanel: false,
        depth: parseFloat(boreholeDepthKeys[panelIndex]),
        colorScheme: true,
        ataVisible: ataVisibles[focusedDiagram.panelId],
        setAtaVisible: (value: boolean) => handleAtaVisibility(value, focusedDiagram.panelId)
      })
    })

    return (
      <div className={styles.focusModeContainer} >
        <ComparisonMode
          addSecondPanel={(panelId, revolution) => {
            if (revolution && panelId === selectedPanels[0]) {
              setSecondPanelRevolution(revolution);
            }
            selectedPanels.push(panelId);
          }}
          dataIds={dataIds}
          onExitPress={exitComparisonMode}
        />
      </div>
    )
  }

  useEffect(() => {
    // console.log("Use effect", centerStationId)
    if (allowForceScroll && centerStationId >= 0) {
      panelGridRef.current?.scrollToCell({columnIndex: centerStationId, rowIndex: 0})

      setAllowForceScroll(false)
      removeCenterStationId()
    } else if (!allowForceScroll) {
      setAllowForceScroll(true)
    }
  }, [centerStationId, allowForceScroll])

  return (
    <ImageVisibilityProvider>
      <div
        ref={contentRef} 
        className={styles.content}
        >
        <PaginationPage 
          centerPageIndex={centerStationId}
          setCenterPageIndex={(i) => {
            setCenterStationId(i)
            setScrollToIndex(i);
          }}
          visiblePageStart={visiblePageStart}
          visiblePageStop={visiblePageStop}
          selectedPanels={selectedPanels}
          onFocusClick={handleSpaceKeyDown}
        >
          {defaultMode()}
        </PaginationPage>

        {viewMode === "focus" && focusMode()}

        {viewMode === "comparison" && comparisonMode()}

        {
          modalVisibility &&
          <LabelModal
            setLabelModalVisible={setModalVisibility}
            top={modalPos.top}
            left={modalPos.left}
            onMouseDown={handleMouseDown}
          />
        }
    </div>
  </ImageVisibilityProvider>
  )
}