import { FormControlLabel } from "@mui/material"
import { IOSSwitch } from "./IOSSwitch"
import styles from "./Toggle.module.css"
import { formLabel } from "styles/MuiSx"


interface ToggleProps {
  classNameOverride?: string,
  text: string,
  checked: boolean, 
  onChange: (checked: boolean) => void,
  labelPlacement: "start" | "bottom" | "top" | "end" | undefined
}

export const Toggle = (props: ToggleProps) => {
  const {text, checked, onChange, labelPlacement} = props
  
  return (
    <FormControlLabel
      sx={formLabel}
      className={`${styles.formControlLabel} ${props.classNameOverride}`}
      labelPlacement={labelPlacement} 
      checked={checked}
      control={<IOSSwitch onChange={(e, checked) => onChange(checked)} />}
      label={text} />
  )
}