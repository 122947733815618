import { Button, Menu, MenuItem } from "@mui/material"
import { LabelIcon, LabelType } from "components/types/LabelType"
import { Arrow } from "images/Dynamic/Arrow"
import { useState } from "react"
import styles from "./LabelTypeDropdown.module.css"
import { Marker } from "components/ScannedImage/MarkerComponents"
import { Label } from "models/Label"


interface LabelTypeDropdownProps {
  selectedType: LabelType | undefined,
  onOptionSelect: (labelType: LabelType) => void
}

export const LabelTypeDropdown = (props: LabelTypeDropdownProps ) => {
  const {selectedType} = props
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const keys = Object.keys(LabelIcon) as Array<keyof typeof LabelIcon>;
  const labelTypes = keys.map((k) => `${k} - ${LabelIcon[k].name}`)
  

  const handleClose = (index: number) => {
    setOpenMenu(false);
    setAnchorEl(null);
    props.onOptionSelect(keys[index]);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(prev => !prev);
  };
  
  const optionRow = (labelType: keyof typeof LabelIcon) => {
    return (
      <div>
        <Marker cx={0} cy={0} labelType={labelType} highlight={false} />
        <span className={styles.optionTextBold}>{labelType}</span>
        <span className={styles.optionText}>{LabelIcon[labelType].name}</span>
      </div>
    
    )
  }
  return (
    <>
      <Button 
        className={styles.dropdownButton}
        id="basic-button"
        onClick={handleClick}
        style={{color: selectedType ? (selectedType.length > 0 ? "var(--default-text)" : "var(--darker-text)") : "var(--darker-text)"}}
        aria-controls={openMenu ? 'label-type-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        endIcon={
          <div className={styles.arrowContainer}>
          <Arrow
            rotate={90}
            width={10}
            height={10}
            color={"var(--accent)"} />
          </div>
         
        }>
        {
          selectedType && selectedType.length > 0 ? 
          optionRow(selectedType)
          :
          "Select a type"
        }
      </Button>


      <Menu
      id="label-type-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      >
        {
          labelTypes.map((text, i) => {
            return (
              <MenuItem 
                key={crypto.randomUUID()}
                sx={{
                  color: "var(--default-text)",
                  '&.MuiMenuItem-root': {
                    background: "var(--header-background)",
                    "&:hover": {
                      background: "var(--content-background)"
                    }
                  }
                }}
                onMouseDown={(e) => {
                  handleClose(i)
                }}
                >
               {optionRow(keys[i])}
              </MenuItem>
            )
          })
        }
      </Menu>
      </>
  )
}