import React, { useRef, useEffect } from 'react';
import { useCoreSampleSlicerViewModel } from './CoreSampleSlicerViewModel';
import styles from "./CoreSampleSlicer.module.css"

function CoreSampleSlicer() {
  const viewModel = useCoreSampleSlicerViewModel();
  const imageRef = useRef(null);
  const labelInputRef = useRef(null);

  // Effect to focus the input when marker is selected
  useEffect(() => {
    if (viewModel.type === 'marker' && labelInputRef.current) {
      labelInputRef.current.focus();
    }
  }, [viewModel.type]);

  // Function to handle key down events
  const handleKeyDown = (e) => {
    console.log(e.key)
    if (e.key === 'Enter') {
      e.preventDefault();
      viewModel.handleAdd();
    }
  };

  const handleImageClick = (e) => {
    e.currentTarget.focus();

    viewModel.handleImageClick(e, imageRef);
  };

  return (
    <form className={styles.coreSampleSlicer} onKeyDown={handleKeyDown}>
      <header className={styles.coreSampleSlicerHeader}>
        <input 
          type="file" 
          accept="image/*" 
          onChange={(e) => viewModel.handleImageChange(e.target.files[0])} 
        />
        {viewModel.selectedImage && (
          <>
          <div className={styles.imageContainer}>
            <img
              ref={imageRef}
              src={viewModel.selectedImage}
              alt="Selected"
              onClick={handleImageClick}
              className={styles.selectedImage}
              tabIndex="0"
            />
            {viewModel.clickedCoords.map((coord, index) => (
              <div
                key={`clicked-${index}`}
                className={`${styles.coordinateMarker} ${coord.type}`}
                style={{
                  left: `${coord.displayX}px`,
                  top: `${coord.displayY}px`,
                }}
              />
            ))}
            {viewModel.currentCoords.map((coord, index) => (
              <div
                key={`current-${index}`}
                className={`${styles.coordinateMarker} ${styles.default}`}
                style={{
                  left: `${coord.displayX}px`,
                  top: `${coord.displayY}px`,
                }}
              />
            ))}
            </div>
            <div className={styles.annotationContainer}>
              <div className={styles.annotationForm}>
                <div className={styles.radioGroup}>
                  <span>
                    <input 
                      type="radio" 
                      value="rod" 
                      checked={viewModel.type === 'rod'} 
                      onChange={() => viewModel.handleTypeChange('rod')}
                    /> Rod
                  </span>
                  <span>
                    <input 
                      type="radio" 
                      value="marker" 
                      checked={viewModel.type === 'marker'} 
                      onChange={() => viewModel.handleTypeChange('marker')}
                    /> Marker
                  </span>
                  {viewModel.type === 'marker' && (
                    <span className={styles.labelInput}>
                      <input 
                      ref={labelInputRef}
                        type="number" 
                        value={viewModel.labelNumber} 
                        onChange={(e) => viewModel.handleLabelNumberChange(e.target.value)}
                        max="9999"
                      />
                      <span>m</span>
                    </span>
                  )}
                </div>
                <div className={styles.coordinatesDisplay}>
                  <span className={styles.smallCoordinates}>
                    Coordinates: {viewModel.orderCoordinates(viewModel.currentCoords).map(coord => `(${coord.x}, ${coord.y})`).join(', ')}
                  </span>
                  <button type="button" onClick={viewModel.handleReset}>Reset</button>
                </div>
                <button type="button" onClick={viewModel.handleAdd}>Add</button>
              </div>
              <div className={styles.jsonDisplay}>
                <textarea
                  readOnly
                  value={JSON.stringify(viewModel.markedData, null, 2)}
                  className={styles.jsonTextarea}
                />
              </div>
            </div>
          </>
        )}
      </header>
    </form>
  );
}

export default CoreSampleSlicer;