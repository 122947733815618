import { Runs } from "hooks/useProjectData";

export const RevolutionList = (runs: Runs) => {
  const result: string[] = [];
  for (const [runKey, run] of Object.entries(runs)) {
    if (run.revs) {
      for (const revKey of Object.keys(run.revs)) {
        result.push(`${runKey} - ${revKey}`);
      }
    }
  }
  return result;
}
