import './styles/colors.css';
import styles from "./ProjectNavigation.module.css"
import { LabelList } from 'components/LabelList/LabelList'
import { MousePositionProvider } from './context/MousePositionContext';
import { MainContent } from "components/MainContent/MainContent";
import { TopBar } from "components/TopBar/TopBar";
import { LabelModalProvider } from "context/LabelModalProvider";
import useProjectData, { Project } from "hooks/useProjectData";
import { useSessionStorage } from "usehooks-ts";
import { useEffect, useState } from "react";
import { Checkbox, CircularProgress } from "@mui/material";

function ProjectNavigation() {
  
  const [debugMode, setDebugMode] = useSessionStorage("debug-mode", false)

  const {isLoading, project} = useProjectData(debugMode)
  const [, setProject, ] = useSessionStorage('project-sokoman', {})

  useEffect(() => {
    console.log("loading manifest", isLoading)
    if (!isLoading) {
      setProject(project)
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div className={styles.loadingContainer} >
        <CircularProgress size="20px" sx={{color: "var(--accent)"}} />
      </div>
    )
  }
  return (
    <div className={styles.ProjectNavigation} >
      <div className="flex flex-row">
        {/* <Checkbox value={debugMode} onChange={(e, checked) => setDebugMode(checked)}/> */}
      <TopBar />
      </div>
      <div className={styles.content}>
        
        <LabelModalProvider>
          <LabelList />

          <MousePositionProvider>
          
              <MainContent/>

          </MousePositionProvider>
        </LabelModalProvider>
      </div>
    </div>

  );
}

export default ProjectNavigation;
