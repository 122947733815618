import { Pagination, PaginationItem, Button } from "@mui/material";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import styles from "./PaginationPage.module.css"
import PaginationEnd from "images/PaginationEdge.svg"
import { FixedSizeList } from 'react-window';
import FocusIcon from "images/FocusIcon.svg"
import SpaceBar from "images/SpaceBar.svg"
import { Project } from "hooks/useProjectData";
import { useSessionStorage } from "usehooks-ts";

interface PaginationPageProps {
  children: React.ReactNode;
  selectedPanels: number[];
  centerPageIndex: number;
  setCenterPageIndex: (index: number) => void;
  visiblePageStart: number;
  visiblePageStop: number;
  onFocusClick: () => void
}

export const PaginationPage = (props: PaginationPageProps) => {
  const { setCenterPageIndex, onFocusClick, visiblePageStart, visiblePageStop, selectedPanels } = props
  const [project] = useSessionStorage<Project>('project-sokoman', {})
  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})


  const [numPages, setNumPages] = useState(0)

  const paginationRef = useRef<FixedSizeList<any>>(null)
  const [scrollToIndex, setScrollToIndex] = useState(0);

  useEffect(() => {
    try {
      const numDepths = Object.keys(project[currentBorehole.project].boreholes[currentBorehole.borehole].depths).length
      setNumPages(numDepths)
    } catch(e) {

    }
   
  },[currentBorehole])

  const Column = ({ data, index, style }: { data: any, index: number, style: any }) => {
    const pageNumber = index + 1
    const isPageVisible = index >= visiblePageStart && index <= visiblePageStop
    const isSelected = selectedPanels?.includes(index);
    const label = pageNumber % 2 === 0 ? `${pageNumber * 1.5}m` : ""

    return (
      <div style={style}>
        <button
          onClick={() => {
            setCenterPageIndex(index)
          }}
          onMouseUp={() => {
            setCenterPageIndex(index)
            console.log("page button clicked", index)

          }}
          className={styles.pageButtonContainer}>

          <div className={styles.rulerContainer} style={{visibility: "hidden"}}>
            <div className={`${styles.ruler} ${label.length === 0 ? styles.long : styles.short} ${isSelected ? styles.highlighted : ""}`} />
            <span className={`${styles.rulerValue} ${isSelected ? styles.highlighted : ""}`}>{label}</span>

          </div>
          <div className={`noselect ${styles.paginationItem} ${isPageVisible ? styles.visible : styles.invisible} ${isSelected ? styles.selected : ""}`}>
            {index + 1}
          </div>
        </button>
      </div>
    )
  }


  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation()

    let delta;
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      delta = event.deltaX
    } else {
      delta = event.deltaY
    }

    let newCenter;
    if (delta < 0) {
      newCenter = Math.max(scrollToIndex - 1, 0)
    } else {
      newCenter = Math.min(scrollToIndex + 1, numPages - 1)
    }

    setScrollToIndex(newCenter)

    paginationRef.current?.scrollToItem(newCenter, "start");
  }

  return (
    <div className={styles.container}>
      <div className={styles.paginationContainer} >
        <div className="flex flex-row">
          <Button
            onClick={() => {
              setCenterPageIndex(0)
              paginationRef.current?.scrollToItem(0)
            }}
            sx={{ color: "var(--accent)", fontSize: "0.5rem" }}
            variant="text"
            endIcon={<img src={PaginationEnd} />}
          >
            TO SURFACE
          </Button>
          
          {/* dummy */}
          <div className={`${styles.focusView}`} style={{visibility: "hidden"}}/>
        </div>


        <div className="scroll-container"
          style={{ height: "fit-content", width: "fit-content" }}
          onWheel={handleWheel}
        >

          <FixedSizeList
            ref={paginationRef}
            direction="ltr"
            className="no-scrollbars noselect"
            layout="horizontal"
            height={70} width={700}
            itemCount={numPages}
            itemSize={32}
            style={{ userSelect: "none" }}
          >
            {Column}
          </FixedSizeList>
        </div>

        <div className="flex flex-row">
          <button 
            className={styles.focusView} 
            style={{ visibility: selectedPanels.length > 0 ? "visible" : "hidden" }}
            onClick={onFocusClick}
          >
            <img src={FocusIcon}/>Focus View<img src={SpaceBar} />
          </button>

          <Button
            sx={{ color: "var(--accent)", fontSize: "0.5rem" }}
            variant="text"
            onClick={() => {
              setCenterPageIndex(numPages - 1)
              paginationRef.current?.scrollToItem(numPages - 1)
            }}
            startIcon={<img className="flip-horizontal" src={PaginationEnd} />}
            className={styles.edgeLabel}>
            TO DEEPEST
          </Button>
        </div>


      </div>

      {props.children}
    </div>

  )
}