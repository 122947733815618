import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './StationPanel.module.css';
import 'styles/global.css'
import { useMousePosition } from 'context/MousePositionContext';
import hideIcon from 'images/Invisible.svg'
import { Checkbox, CircularProgress } from "@mui/material";
import { useImageVisibility } from "context/ImageVisibilityContext";
import { StationPanelTitle } from "./StationPanelTitle";
import { ScannedImage } from "components/ScannedImage/ScannedImage";
import { ViewMode } from "components/types/ViewMode";
import { DiagramType } from "components/types/DiagramType";
import { Revolution } from "components/types/Revolution";
import useProjectData, { Project } from "hooks/useProjectData";
import { useSessionStorage } from "usehooks-ts";
import { RevolutionList } from "utils/RevolutionList";

export interface StationPanelProps {
  onPanelClick?: () => void;
  panelId: number;
  viewMode: ViewMode;
  outlinePanel: boolean;
  revolution: Revolution;
  onRevolutionChange: (value: Revolution) => void;
  hideHorizons?: boolean;
  hideLabels?: boolean;
  depth: number;
  colorScheme: boolean;
  ataVisible: boolean;
  setAtaVisible: (flag: boolean) => void;
}

export const DataNoteSection = (assayComments: string[]) => {
  return (
    <div className={styles.commentContainer}>
      <span className={styles.commentTitle}>ASSAY COMMENTS</span>
      <span className={styles.commentText}>{assayComments.join(", ")}</span>

      <div className={styles.dataListContainer}>
        <div className={styles.dataItemContainer}>
          <span className={styles.dataTitle}>+150 Mesh wt (g) Metallic Sieve</span>
          <span className={styles.commentText}>37.69</span>
        </div>
        <div className={styles.dataItemContainer}>
          <span className={styles.dataTitle}>+150 Mesh wt (g) Metallic</span>
          <span className={styles.commentText}>37.69</span>
        </div>
        <div className={styles.dataItemContainer}>
          <span className={styles.dataTitle}>+150 Mesh wt (g) Metallic Sieve</span>
          <span className={styles.commentText}>37.69</span>
        </div>
        <div className={styles.dataItemContainer}>
          <span className={styles.dataTitle}>+150 Mesh wt (g) Metallic</span>
          <span className={styles.commentText}>37.69</span>
        </div>

      </div>
    </div>
  )
}

export const StationPanel = (props: StationPanelProps) => {

  const { radargramPos, polargramPos, isPointerVisible, hoveredImage } = useMousePosition();
  
    const {
      syncAtaVisibility,
      syncCoreSampleVisibility,
      globalAtaVisibility,
      setGlobalAtaVisibility,
      globalCoreSampleVisibility,
      setGlobalCoreSampleVisibility,
      onSyncAtaVisibilityChanged,
      onSyncCoreSampleVisibilityChanged
    } = useImageVisibility(); 

    const { panelId, viewMode, outlinePanel, revolution, onPanelClick, colorScheme, depth,
      ataVisible, setAtaVisible
     } = props
  
  const radargramRef = useRef<HTMLDivElement>(null);
  const polargramRef = useRef<HTMLDivElement>(null);
  const ataRef = useRef<HTMLDivElement>(null);
  const coreSampleRef = useRef<HTMLDivElement>(null);
  const coreSampleZoomableRef = useRef<HTMLDivElement>(null);

  // const [ataVisible, setAtaVisible] = useState(true)
  const [coreSampleVisible, setCoreSampleVisible] = useState(true)

  const [radargramScale, setRadargramScale] = useState<number>(1);
  const [radargramOffset, setRadargramOffset] = useState({ x: 0, y: 0 });

  const [polargramScale, setPolargramScale] = useState<number>(1);
  const [polargramOffset, setPolargramOffset] = useState({ x: 0, y: 0 });

  const [coreSampleScale, setCoreSampleScale] = useState<number>(1);
  const [coreSampleOffset, setCoreSampleOffset] = useState({ x: 0, y: 0 });

  const [radargramImg, setRadargramImg] = useState("")
  const [polargramImg, setPolargramImg] = useState("")
  const [ataImg, setAtaImg] = useState("")
  const [coreSampleImg, setCoreSampleImg] = useState("")
  const [assayComments, setAssayComments] = useState<string[]>([])

  const [focusedDiagram, setFocusedDiagram] = useState<DiagramType>("none")
  
  // const {isLoading} = useProjectData(false)
  const [project] = useSessionStorage<Project>('project-sokoman', {})

  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})
  const [revolutionOptions, setRevolutionOptions] = useState<string[]>([])

  const getImageUrl = (fileName: string | null) => {
    if (fileName == null || fileName.length === 0) return ""
    const url = `${process.env.REACT_APP_API_BASEPATH}/static/${fileName}`
    return url
  }
  useEffect(() => {
    try {
      const stationData = project[currentBorehole.project]?.boreholes[currentBorehole.borehole]?.depths[depth]
    
      // console.log(stationData)
      const rev = stationData.runs[revolution.runId].revs[revolution.revId]
      setRadargramImg(getImageUrl(`radar_images/${colorScheme ? rev.radar_signal : rev.radar_raw}`))
      setPolargramImg(getImageUrl(`radar_images/${colorScheme ? rev.polar_signal : rev.polar_raw}`))
      setAtaImg(getImageUrl(`radar_images/${rev.ATA}`))
      setCoreSampleImg(getImageUrl(stationData["core sample extract"] == null ? null : `radar_images/${stationData["core sample extract"]}`))
  
      const runs = project[currentBorehole.project].boreholes[currentBorehole.borehole].depths[depth].runs;
      const revolutionList = RevolutionList(runs);
      setRevolutionOptions(revolutionList)
  
      setAssayComments(stationData.assay)
    } catch (e) {
      console.error(e)
      console.log("statoinData", project)
    }

  }, [ revolution, colorScheme])

  useEffect(() => {
    if (viewMode === "focus") {
      setFocusedDiagram(hoveredImage.diagramType)
    }
  }, [viewMode])

  const isHoveringOn = (diagramType: DiagramType) => {
    return hoveredImage?.diagramType === diagramType && hoveredImage.panelId === panelId

  }

  const Radargram = () => {
    return (
      <div style={{ position: "relative" }}>
        <ScannedImage
          panelId={panelId}
          depth={depth}
          revolution={props.revolution}
          isFocusMode={viewMode === "focus"}
          diagramImage={radargramImg}
          diagramType={"radargram"}
          diagramRef={radargramRef}
          pointerX={radargramPos.x}
          pointerY={radargramPos.y}
          isHoveringOn={isHoveringOn}
          hideHorizons={props.hideHorizons}
          hideLabels={props.hideLabels}
          scale={radargramScale}
          setScale={setRadargramScale}
          offset={radargramOffset}
          setOffset={setRadargramOffset}
        />
      </div>
    )
  }

  const Polargram = () => {
    return (
      <div style={{ position: "relative" }}>
        <ScannedImage
          panelId={panelId}
          depth={depth}
          revolution={props.revolution}
          isFocusMode={viewMode === "focus"}
          diagramType={"polargram"}
          diagramImage={polargramImg}
          diagramRef={polargramRef}
          pointerX={polargramPos.x}
          pointerY={polargramPos.y}
          isHoveringOn={isHoveringOn}
          hideHorizons={props.hideHorizons}
          hideLabels={props.hideLabels}
          scale={polargramScale}
          setScale={setPolargramScale}
          offset={polargramOffset}
          setOffset={setPolargramOffset}
        />
      </div>
    )
  }

/***************************************** ATA *****************************************/
  const Ata = () => {
    if (ataVisible) {
      const height = ataRef?.current?.getBoundingClientRect().height ?? 1; // Get the height

      return (
        <div className={styles.ataContainer} ref={ataRef}
          onClick={(e) => e.stopPropagation()}
          style={{
            height: `var(--radargram-width${viewMode === "focus" ? "-focus" : "-dynamic"})`,
          }}>
          <img
            data-key={JSON.stringify({ panelId: panelId, diagramType: "ata" })}
            className={styles.ata}
            src={ataImg}
            style={{
              height: `var(--radargram-width${viewMode === "focus" ? "-focus" : "-dynamic"})`,
            }} />
          {
            isPointerVisible &&
            <div className={styles.ataOverlay} 
              style={{ top: radargramPos.y * height }}>
              <div className="flex flex-row">
                <div className={styles.ataOverlayLine} />
                <span className={`${styles.ataOverlayText} ${isHoveringOn("radargram") ? styles.light : styles.dark}`}>
                  {Math.round(radargramPos.y * 500)}cm
                </span>
              </div>
            </div>
          }
          {
            (isHoveringOn("ata") && viewMode !== "focus") &&
            <>
             <div className={styles.focusedOverlay}/>
            { VisibilityContainer("ata")}
            </>
          }
        </div>
      )
    } else {
      return (
        <div 
          onMouseDown={handleShowAta}
          className={styles.showAtaBarContainer}>
          <div className={styles.showAtaBar}/>
        </div>
      )
    }
  }

  const handleShowAta = () => {
    setAtaVisible(true)

    if (syncAtaVisibility) {
      setGlobalAtaVisibility(true)
    }
  }

  useEffect(() => {
    if (syncAtaVisibility && globalAtaVisibility !== ataVisible) {
      setAtaVisible(globalAtaVisibility)
    }
  }, [syncAtaVisibility, globalAtaVisibility])

  /***************************************** CoreSample *****************************************/
  const CoreSample = () => {
    if (viewMode === "focus") return CoreSampleFocus();

    if (coreSampleVisible && coreSampleImg != "") {
      return (
        <div className={`${styles.core} ${!ataVisible && styles.narrow}`} ref={coreSampleRef}
          onClick={onImageClick}>
          <img className={styles.coreImage}
            src={coreSampleImg}
            data-key={JSON.stringify({ panelId: panelId, diagramType: "coreSample" })}
          />
          {
            (isHoveringOn("coreSample")) &&
            <>
              {VisibilityContainer("coreSample")}
              <div className={styles.focusedOverlay}></div>
            </>
  
          }
        </div>
      )
    } else {
      return (
        <div 
          onMouseDown={handleShowCoreSample}
          className={`${styles.showCoreSampleBarContainer} ${!ataVisible && styles.narrow}`}>
          <div className={styles.showCoreSampleBar}/>
        </div>
      )
    }
   
  }

  const onImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const needToSlice = true // todo
    if (needToSlice) {

    }
  }

  const CoreSampleFocus = () => {
    return (
      <div className={styles.coreSampleZoomArea} >
        <img src={coreSampleImg} className="full-width"
          data-key={JSON.stringify({ panelId: panelId, diagramType: "coreSample" })}
        />
        <div ref={coreSampleZoomableRef}
          className={styles.coreSampleZoomImage}>
          {/* <img src={core} className="full-width"
            style={{
              transform: `scale(${radargramScale}) translate(-${radargramOffset.x}px, 0px)`,
              transformOrigin: '0 0',
            }}
          /> */}
          <div style={{ position: "relative", 
              display: "flex",
              width: "100%",
              height: "100%"  }}>
            <ScannedImage
              panelId={panelId}
              depth={depth}
              revolution={props.revolution}
              isFocusMode={viewMode === "focus"}
              diagramImage={coreSampleImg}
              diagramType={"coreSample"}
              diagramRef={radargramRef}
              pointerX={radargramPos.x}
              pointerY={radargramPos.y}
              isHoveringOn={isHoveringOn}
              hideHorizons={props.hideHorizons}
              hideLabels={props.hideLabels}
              scale={coreSampleScale}
              setScale={setCoreSampleScale}
              offset={coreSampleOffset}
              setOffset={setCoreSampleOffset}
            />
          </div>
        </div>

      </div>
    )
  }

  const handleOnHideCoreSampleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();

    if (syncCoreSampleVisibility) {
      setGlobalCoreSampleVisibility(false)
    } else {
      setCoreSampleVisible(false);

    }
  }

  const handleShowCoreSample = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (syncCoreSampleVisibility) {
      setGlobalCoreSampleVisibility(true)
    } else {
      setCoreSampleVisible(true)

    }
  }

  useEffect(() => {
    setCoreSampleVisible(globalCoreSampleVisibility)
  }, [globalCoreSampleVisibility])

  /********************** Shared With ATA And CoreSample *****************************/
  const handleOnHideAtaClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setAtaVisible(false);

    if (syncAtaVisibility) {
      setGlobalAtaVisibility(false)
    }

  }

  const VisibilityContainer = (diagramType: DiagramType) => {
    const onHideClick = diagramType === "ata" ? handleOnHideAtaClick : handleOnHideCoreSampleClick

    return (
      <div className={styles.visibilitySettingContainer}
        style={{bottom: diagramType === "ata" ? "4px" : "0px",
          right:  diagramType === "ata" ? "4px" : "0px"
        }}
      >
        <img onMouseDown={onHideClick}
          data-key={JSON.stringify({ panelId: panelId, diagramType: diagramType })}
          src={hideIcon} />
        {syncCheckbox(diagramType)}
      </div>
    )
  }

  const syncCheckbox = (diagramType: DiagramType) => {
    let checked;
    let onCheckboxClick: (flag: boolean) => void;

    if (diagramType === "coreSample") {
      checked = syncCoreSampleVisibility
      onCheckboxClick = onSyncCoreSampleVisibilityChanged
    } else {
      checked = syncAtaVisibility
      onCheckboxClick = onSyncAtaVisibilityChanged
    }

    return (
      <div className={styles.syncCheckboxContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}>
        <Checkbox
          data-key={JSON.stringify({ panelId: panelId, diagramType: diagramType })}
          checked={checked}
          className={styles.checkbox}
          onChange={(e, checked) => {
            e.stopPropagation()
            onCheckboxClick(checked)}
          }
          sx={{
            '&.Mui-checked': { color: "var(--dark-text)" },
            "& .MuiSvgIcon-root": {
              width: "12px",
              padding: "0px"
            },
          }}
        />
        <span
          data-key={JSON.stringify({ panelId: panelId, diagramType: diagramType})}
          className={styles.checkboxLabel}>ALL</span>
      </div>

    )
  }

  /************************************ View Modes ***************************************/
  const defaultMode = () => {
    return (
      <div className={styles.defaultModeContainer}>
        <CoreSample />

        <div className={styles.graphsContainer} >
          <div className={`${styles.radargramPolargram} ${styles.narrow}`}>
            <Radargram />
            <Polargram />
          
          </div>
          <Ata />
        </div>
      </div>
    )
  }

  const focusMode = () => {
    switch (focusedDiagram) {
      case "ata":
        return <Ata />
      case "coreSample":
        return <CoreSample />
      case "radargram":
        return <Radargram />
      case "polargram":
        return <Polargram />
      default:
        return null
    }
  }

  // if (isLoading) {
  //   return <div className={`${styles.container} ${styles.loadingContainer}`}>
  //     <CircularProgress size="20px" sx={{color: "var(--accent)"}} />
  //   </div>
  // }

  return (
    <div className={styles.container}
      style={{ outlineWidth: outlinePanel ? "1px" : "0px",
        width:  (!ataVisible ? "var(--station-panel-narrow)" : "auto" )
      }}
      onClick={onPanelClick} >
        <StationPanelTitle 
          depth={depth}
          panelId={panelId}
          options={revolutionOptions}
          revolution={props.revolution}
          onChangeRevolution={props.onRevolutionChange}
        />
      <div className={styles.diagramContainer}>
        {
          viewMode === "focus" ? focusMode() : defaultMode()
        }
      </div>

        {
          DataNoteSection(assayComments)
        }
    </div>

  )
}