import CoreSampleSlicer from "CoreSampleSlicer/CoreSampleSlicer";
import ProjectNavigation from "ProjectNavigation";
import { type RouteObject, createBrowserRouter, Navigate, Outlet } from "react-router-dom";

const router: RouteObject[] = [
  {
    path: "*",
    element: (
      <div>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "projects",
        children: [
          {
            index: true,
            element: <ProjectNavigation />,
          },
          {
            path: "slicer",
            element: <CoreSampleSlicer />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/projects" />,
      },
    ],
  },
  { index: true, element: <Navigate to="/projects" /> },
];

export default createBrowserRouter(router);
