import { LabelType } from "components/types/LabelType";
import { FirstArrivalIcon } from "images/LabelIcons/FirstArrivalIcon";
import FirstArrivalsIcon from "images/LabelIcons/FirstArrivalsIcon.svg"
import { FootWallIcon } from "images/LabelIcons/FootWallIcon";
import { HangingWallIcon } from "images/LabelIcons/HangingWallIcon";
import { InternalVeinIcon } from "images/LabelIcons/InternalVeinIcon";
import { UnknownIcon } from "images/LabelIcons/UnknownIcon";
import { VeinFootUnknownIcon } from "images/LabelIcons/VeinFootUnknownIcon";

export const Arc = ({ point1, point2, imageSize, center, highlight }: {point1: {x: number, y: number}, point2: {x: number, y: number}, imageSize: number, center: {x: number, y: number}, highlight: boolean}) => {

  const radius1 = Math.sqrt(
    Math.pow(point1.x - center.x, 2) + Math.pow(point1.y - center.y, 2)
  );
  const radius2 = Math.sqrt(
    Math.pow(point2.x - center.x, 2) + Math.pow(point2.y - center.y, 2)
  );

  // Get the average radius
  const radius = (radius1 + radius2) / 2

  const largeArcFlag = isLargeArcFlag({point1, point2, center: center.x});
  const sweepFlag = 0; // Counter-clockwise

  const pathData = `
    M ${point1.x} ${point1.y} 
    A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${point2.x} ${point2.y}
  `;

  return (
    <svg width={imageSize} height={imageSize}>
      <path d={pathData} fill="none" stroke="#dcb749" strokeWidth={"2"} />
    </svg>
  );
};

const isLargeArcFlag = ({ point1, point2, center }: {point1: {x: number, y: number}, point2: {x: number, y: number}, center: number}) => {
  const v1 = { x: point1.x - center, y: point1.y - center };
  const v2 = { x: point2.x - center, y: point2.y - center };

  // Calculate dot product
  const dotProduct = v1.x * v2.x + v1.y * v2.y;

  // Calculate cross product (determinant)
  const crossProduct = v1.x * v2.y - v1.y * v2.x;

  // Angle in radians using atan2 (handles counterclockwise correctly)
  let angle = Math.atan2(crossProduct, dotProduct);

  // Normalize angle to [0, 2π]
  if (angle < 0) {
    angle += 2 * Math.PI;
  }

  const largeArcFlag = angle > Math.PI ? 0 : 1;

  return largeArcFlag;
}

const labelIcons: { [key: string]: React.FC<{ cx: number; cy: number; highlight: boolean }> } = {
  hw: HangingWallIcon,
  fw: FootWallIcon,
  vfunk: VeinFootUnknownIcon,
  inv: InternalVeinIcon,
  fai: FirstArrivalIcon,
  unk: UnknownIcon
};

export const Marker = ({cx, cy, labelType, highlight}: {cx: number, cy: number, labelType: LabelType, highlight: boolean}) => {
  const LabelComponent = labelIcons[labelType] || UnknownIcon;

  const scale = highlight ? 1.3 : 1;

  return (
    <LabelComponent cx={cx} cy={cy} highlight={highlight}/>
  );
}
export const Line = ({ x1, x2, y1, y2, highlight }: {x1: number, y1: number, x2: number, y2: number, highlight: boolean}) => (
  <line
    x1={x1}
    y1={y1}
    x2={x2}
    y2={y2}
    stroke="#dcb749"
    strokeWidth={"2"}
  />
)