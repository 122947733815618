import React, { createContext, useContext, useState, useRef, useEffect, useLayoutEffect } from 'react';
import {polargramToRadargram, radargramToPolargram} from "utils/Conversion"
import { useDebounceCallback } from "usehooks-ts";
import { DiagramType } from "components/types/DiagramType";

interface MousePositionContextType {
  radargramPos: { x: number, y: number };
  polargramPos: { x: number, y: number };
  isPointerVisible: boolean;
  hoveredImage: { panelId: number, diagramType: DiagramType, depth: number };
  setHoveredRadargramRef: (ref: React.RefObject<HTMLDivElement> | undefined) => void;
  setHoveredPolargramRef: (ref: React.RefObject<HTMLDivElement> | undefined) => void;
}

const defaultContextValues: MousePositionContextType = {
  radargramPos:  { x: -1, y: -1 },
  polargramPos:  { x: -1, y: -1 },
  isPointerVisible: false,
  hoveredImage: {panelId: -1, diagramType: "none", depth: 0},
  setHoveredRadargramRef: () => { },
  setHoveredPolargramRef: () => { },
};

const MousePositionContext = createContext<MousePositionContextType>(defaultContextValues);

export const useMousePosition = () => {
  return useContext(MousePositionContext);
};

export const MousePositionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [radargramX, setRadargramX] = useState(-1);
  const [radargramY, setRadargramY] = useState(-1);
  const [polargramX, setPolargramX] = useState(-1);
  const [polargramY, setPolargramY] = useState(-1);
  const [isPointerVisible, setIsPointerVisible] = useState(false);
  const [hoveredImage, setHoveredImage] = useState<{ panelId: number, diagramType: DiagramType, depth: number }>({panelId: -1, diagramType: "none", depth: 0})

  const debouncedSetIsPointerVisible = useDebounceCallback(setIsPointerVisible, 5)
  const debouncedSetHoveredImage = useDebounceCallback(setHoveredImage, 5)

  const radargramRef = useRef<HTMLDivElement | null>(null);
  const polargramRef = useRef<HTMLDivElement | null>(null);
  const imageSizeRef = useRef<number | null>(null);
  const radargramLeftRef = useRef(-1)
  const radargramTopRef = useRef(-1)
  const polargramLeftRef = useRef(-1)
  const polargramTopRef = useRef(-1)

  useLayoutEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      let target = document.elementFromPoint(event.clientX, event.clientY)
      if (target != null) {
        let data = target.getAttribute("data-key");
      
        // Check if the parent element is one of the targeted images
        for (let i = 0; i < 3 && data == null; i++) {
          if (target == null) break;

          target = target.parentElement;
          if (target != null) {
              data = target.getAttribute("data-key");
              
          }
        }
      
        if (data != null && target != null) {
            const {panelId, diagramType, depth } = JSON.parse(data);
            setHoveredImage({panelId, diagramType, depth});

            if (diagramType === "radargram") {
                handleMouseOnRadargram(event, target);
            } else if (diagramType === "polargram") {
                handleMouseOnPolargram(event, target);
            } else {
                debouncedSetIsPointerVisible(false);
            }
        } else {
          debouncedSetIsPointerVisible(false);
          debouncedSetHoveredImage({panelId: -1, diagramType: "none", depth: 0});
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const scaleData = (target: Element) => {
    const style = window.getComputedStyle(target);
    const transform = style.transform;

    // Extract scale and translate values from the transform
    let scale = 1; // Default scale
    let translateX = 0; // Default translateX
    let translateY = 0; // Default translateY

    if (transform && transform !== 'none') {
        const matrix = new DOMMatrix(transform);
        scale = matrix.a; // Scale is represented by the 'a' value in the matrix
        translateX = matrix.e; // TranslateX is represented by the 'e' value
        translateY = matrix.f; // TranslateY is represented by the 'f' value
    }
    return  {scale, translateX, translateY}
  }

  const handleMouseOnRadargram = (event: MouseEvent, target: Element) => {
    if (!radargramRef.current || !imageSizeRef.current) return;
    if (radargramLeftRef.current <= 0 || radargramTopRef.current <= 0) return;

    setIsPointerVisible(true);
    
    // Get scale and translation data
    const { scale, translateX, translateY } = scaleData(target);

    // Adjust mouse coordinates based on scale and translation
    const x = (event.clientX - radargramLeftRef.current - translateX) / scale;
    const y = (event.clientY - radargramTopRef.current - translateY) / scale;

    // Normalize coordinates relative to image dimensions
    const radargramWidth = imageSizeRef.current; // Assuming you have access to the width
    const radargramHeight = imageSizeRef.current; // Assuming you have access to the height
    const normalizedX = x / radargramWidth;
    const normalizedY = y / radargramHeight;

    setRadargramX(normalizedX);
    setRadargramY(normalizedY);

    const { x: polarX, y: polarY } = radargramToPolargram(imageSizeRef.current ?? 0, imageSizeRef.current ?? 0, imageSizeRef.current ?? 0, x, y);

    const normalizedPolarX = polarX / imageSizeRef.current
    const normalizedPolarY = polarY / imageSizeRef.current
    setPolargramX(normalizedPolarX);
    setPolargramY(normalizedPolarY);
  };

  const handleMouseOnPolargram = (event: MouseEvent, target: Element) => {
    if (!polargramRef.current || !imageSizeRef.current) return;
    if (polargramLeftRef.current <= 0 || polargramTopRef.current <= 0) return;

    setIsPointerVisible(true);

    // Get scale and translation data
    const { scale, translateX, translateY } = scaleData(target);

    // Adjust mouse coordinates based on scale and translation
    const x = (event.clientX - polargramLeftRef.current - translateX) / scale;
    const y = (event.clientY - polargramTopRef.current - translateY) / scale;

    // Normalize coordinates relative to image dimensions
    const polargramWidth = imageSizeRef.current; // Assuming you have access to the width
    const polargramHeight = imageSizeRef.current; // Assuming you have access to the height
    const normalizedX = x / polargramWidth;
    const normalizedY = y / polargramHeight;

    setPolargramX(normalizedX);
    setPolargramY(normalizedY);

    const { radarX, radarY } = polargramToRadargram(imageSizeRef.current ?? 0, x, y);

    const normalizedRadarX = radarX / imageSizeRef.current
    const normalizedRadarY = radarY / imageSizeRef.current

    setRadargramX(normalizedRadarX);
    setRadargramY(normalizedRadarY);
  };

  const setHoveredRadargramRef = (ref?: React.RefObject<HTMLDivElement>) => {
    if (ref) {
      radargramRef.current = ref.current;
      polargramRef.current = null
      if (ref.current != null) {
        imageSizeRef.current = ref.current.offsetWidth;

        const { left, top } = ref.current.getBoundingClientRect();
        if (left > 0 && top > 0) {
          radargramTopRef.current = top
          radargramLeftRef.current = left
        }
      }
    } 
  }

  const setHoveredPolargramRef = (ref?: React.RefObject<HTMLDivElement>) => {
    if (ref) {
      polargramRef.current = ref.current;
      radargramRef.current = null
      if (ref.current != null) {
        imageSizeRef.current = ref.current.offsetWidth;

        const { left, top } = ref.current.getBoundingClientRect();
        if (left > 0 && top > 0) {
          polargramTopRef.current = top
          polargramLeftRef.current = left
        }
      }
    }
  }


  return (
    <MousePositionContext.Provider 
      value={{ radargramPos: {x: radargramX, y: radargramY}, 
        polargramPos: { x: polargramX, y: polargramY},
        isPointerVisible, hoveredImage,
        setHoveredRadargramRef, setHoveredPolargramRef }}>
      {children}
    </MousePositionContext.Provider>
  );
};


