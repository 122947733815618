import { Revolution } from "components/types/Revolution";
import styles from "./RevolutionPicker.module.css"
import { Arrow } from "images/Dynamic/Arrow";

interface RevolutionPickerProps {
  selectedIndex: number;
  onChangeValue: (index: number) => void;
  options: string[];
  valueText: string;
  // onChangeValue: (revolution: Revolution) => void;

}

export const RevolutionPicker = (props:RevolutionPickerProps) => {
  const { selectedIndex, onChangeValue, valueText, options } = props
  const numRevolution = options.length;

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (selectedIndex >= 1) {
      onChangeValue(selectedIndex - 1);
    }
  }

  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (selectedIndex < numRevolution - 1) {
      onChangeValue(selectedIndex + 1);
    }
  }

  return (
    <div className={styles.container}>
      <button className={`${styles.arrow} flip-horizontal`}
        onClick={handlePrevious}
        disabled={selectedIndex === 0}
      >
        <Arrow color={selectedIndex === 0 ? "var(--focused-border)" : "var(--accent)"}/>

      </button>
      <span className={`${styles.label} noselect`}
        onClick={handleNext}
        >
        {options[selectedIndex]}
      </span>
      <button className={styles.arrow}
        disabled={selectedIndex === numRevolution - 1}
        onClick={handleNext}
      >
        <Arrow color={selectedIndex === numRevolution - 1 ? "var(--focused-border)" : "var(--accent)"}/>
      </button>
    </div>

  )
}