import { LabelType } from "components/types/LabelType";
import { Coordinates } from "models/Coordinates";
import { Label } from "models/Label";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useSessionStorage } from "usehooks-ts";

interface LabelModalContextType {
  modalVisibility: boolean;
  setModalVisibility: (value: boolean) => void; 
  modalPos: {top: number, left: number};
  setModalPos: (value :{top: number, left: number}) => void;
  tempCoords: {panelId: number, coordinates: Coordinates[], labelType: LabelType};
  setTempCoords: (value: {panelId: number, coordinates: Coordinates[], labelType: LabelType}) => void;
  editLabel?: {stationKey: string, labelIndex: number} | null;
  setEditLabel: (value: {stationKey: string, labelIndex: number} | null) => void;
  editingCoordIndex: number;
  setEditingCoordIndex: (value: number) => void
  cancelLabelCreation: () => void
}

const defaultContextValues: LabelModalContextType = {
  modalVisibility: false,
  setModalVisibility: () => {},
  modalPos: {top: 0, left: 0},
  setModalPos: () => {},
  tempCoords: {panelId: -1, coordinates: [], labelType: "unk"},
  setTempCoords: () => {},
  editLabel: {stationKey: "", labelIndex: -1},
  setEditLabel: () => {},
  editingCoordIndex: -1,
  setEditingCoordIndex: () => {},
  cancelLabelCreation: () => {}
}

const LabelModalContext = createContext<LabelModalContextType>(defaultContextValues);

export const useLabelModal = () => {
  const context = useContext(LabelModalContext);
  return context;
};

export const LabelModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  
  const [modalVisibility, setModalVisibility] = useState<boolean>(defaultContextValues.modalVisibility);
  const [modalPos, setModalPos] = useState<{top: number, left: number}>(defaultContextValues.modalPos);
  const [tempCoords, setTempCoords] = useState<{panelId: number, coordinates: Coordinates[], labelType: LabelType}>(defaultContextValues.tempCoords);
  const [editLabel, setEditLabel] = useState<{stationKey: string, labelIndex: number} | null>(null);
  const [editingCoordIndex, setEditingCoordIndex] = useState<number>(-1)
  const [selectedCoordId, setSelectedCoordId, removeSelectedCoordId] = useSessionStorage('selectedCoordId', "")

  const cancelLabelCreation = () => {
    setTempCoords({panelId: -1, coordinates: [], labelType: "unk"})
    setEditLabel(null)
    setEditingCoordIndex(-1);
    removeSelectedCoordId();
    setModalVisibility(false)

  }

  return (
    <LabelModalContext.Provider 
      value={{ modalVisibility, setModalVisibility, modalPos, setModalPos, tempCoords, cancelLabelCreation,
       setTempCoords, editLabel, setEditLabel, editingCoordIndex, setEditingCoordIndex }}>
      {children}
    </LabelModalContext.Provider>
  );
};

