

export const VeinFootUnknownIcon = ({cx, cy, highlight} : {cx: number, cy: number, highlight: boolean}) => {
  const scale = highlight ? 1.3 : 1

  return (
    <svg x={cx - 5 * scale} y={cy - 5 * scale} width={10 * scale} height={10 * scale} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" strokeWidth={highlight ? 1 : 0}>
      <path fillRule="evenodd" clipRule="evenodd"
        stroke="white" strokeWidth={highlight ? 1 : 0}
        d="M0.5 5.13405C-0.166667 5.51895 -0.166667 6.4812 0.5 6.8661L2.5 8.0208L2.5 10.3302C2.5 11.1 3.33333 11.5811 4 11.1962L6 10.0415L8 11.1962C8.66667 11.5811 9.5 11.1 9.5 10.3302V8.0208L11.5 6.8661C12.1667 6.4812 12.1667 5.51895 11.5 5.13405L9.5 3.97935V1.66995C9.5 0.900149 8.66667 0.419023 8 0.803924L6 1.95862L4 0.803924C3.33333 0.419023 2.5 0.900149 2.5 1.66995L2.5 3.97935L0.5 5.13405Z"
        fill="#F7C368"/>

   
    </svg>


  )
}