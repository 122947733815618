export const saveLabelsToStorage = (project: string, borehole: string, prevState: any, newState: any) => {

  const newAllData = { ...prevState }
          
  if (!newAllData[project]) {
    const newBh = {
      [borehole]: newState
    }
    newAllData[project] = newBh
  } else {
    newAllData[project][borehole] = newState 
  }
  return newAllData
}