export function radargramToPolargram(radarWidth: number, radarHeight: number, polargramSize: number, radargramX: number, radargramY: number) {
  // Center of the polargram
  const centerX = polargramSize / 2;
  const centerY = polargramSize / 2;

  // Calculate the radius proportional to the y-coordinate
  const maxRadius = centerX; // Max radius is half the polargram size
  const r = (radargramY / radarHeight) * maxRadius;

  // Calculate the angle (theta) with offset
  const theta = (radargramX / radarWidth) * 2 * Math.PI - Math.PI * 1.5;

  // Convert polar coordinates (r, theta) to Cartesian coordinates in polargram
  const polargramX = centerX + r * Math.cos(theta);
  const polargramY = centerY - r * Math.sin(theta);

  return { x: polargramX, y: polargramY };
}

export function polargramToRadargram(imageSize: number, x: number, y: number) {
  const centerX = imageSize / 2;
  const centerY = imageSize / 2;

  // Calculate the offset from the center
  const dx = x - centerX;
  const dy = y - centerY;

  // Calculate radius r in polar coordinates (distance from center)
  const maxRadius = centerX; // Max radius is half the polargram size
  const r = Math.sqrt(dx * dx + dy * dy);

  // Calculate the angle θ and adjust for our clockwise rotation
  let theta = Math.atan2(-dy, dx) + Math.PI * 1.5; // Adjust for the coordinate system
  if (theta < 0) {
    theta += 2 * Math.PI; // Ensure θ is within [0, 2π]
  }

  // Convert r and θ back to radargram coordinates
  const radarYTemp = (r / maxRadius) * imageSize;
  const radarXTemp = (theta / (2 * Math.PI)) * imageSize;

  const radarX = radarXTemp >= imageSize ? radarXTemp - imageSize : radarXTemp;
  const radarY = radarYTemp >= imageSize ? radarYTemp - imageSize : radarYTemp;

  return { radarX, radarY };
}

export function getAzimuthFromPolargram(polargramX: number, polargramY: number) {
  const azimuth = (Math.atan2(polargramY - 0.5, polargramX - 0.5)* 180)  / Math.PI;
  
 // Adjust for 90° offset and ensure non-negative
  const adjustedAzimuth = Math.round((azimuth + 90 + 360) % 360);

  return adjustedAzimuth
}

// radargramX and radargramY are normalized value
export function getAzimuthFromRadargram(radargramX: number, radargramY: number) {
  const center = 0.5;
  const r = radargramY * center; // Radius is proportional to y-coordinate
  const theta = radargramX * 2 * Math.PI - Math.PI * 1.5;

  // Convert polar to Cartesian and calculate azimuth
  const polargramX = center + r * Math.cos(theta);
  const polargramY = center - r * Math.sin(theta);
  const azimuth = (Math.atan2(polargramY - center, polargramX - center) * 180) / Math.PI;
   
  // Adjust for 90° offset and ensure non-negative
  const adjustedAzimuth = Math.round((azimuth + 90 + 360) % 360);

  return adjustedAzimuth
}