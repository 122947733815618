
export const LabelIcon = {
  "hw": {
    name: "Hanging Wall",
    icon: ""
  },
  "fw": {
    name: "Foot Wall",
    icon: ""
  },
  "vfunk": {
    name: "Vein Foot Unknown",
    icon: ""
  },
  "inv": {
    name: "Internal Vein",
    icon: ""
  },
  "fai": {
    name: "First Arrivals",
    icon: ""
  },
  "unk": {
    name: "Unknown",
    icon: ""
  }

}

export type LabelType = "hw" | "fw" | "vfunk" | "inv" | "fai" | "unk"
