import { Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import 'styles/global.css'
import styles from "./LabelList.module.css"
import { FilterComponent } from "components/FilterComponent/FilterComponent";
import { useDebounceValue, useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { StationLabelStore } from "models/StationLabelStore";
import { useEffect, useState } from "react";
import Edit from "images/Edit.svg"
import { Invisible } from "images/Dynamic/Invisible";
import { Visible } from "images/Dynamic/Visible";
import { boxStyle } from "styles/MuiSx";
import { Coordinates } from "models/Coordinates";
import { Filter } from "components/types/Filter"
import { Label } from "models/Label";
import { useLabelModal } from "context/LabelModalProvider";
import { CoordToString } from "utils/ToString";
import CsvDownloadButton from 'react-json-to-csv'
import { Toggle } from "components/Toggle/Toggle";
import { saveLabelsToStorage } from "utils/SaveLabelInfo";

export const LabelList = () => {
  const { setEditLabel, setModalVisibility, setModalPos, editLabel } = useLabelModal();
  const [, setCenterStationId, ] = useSessionStorage('centerStationId', -1)
  const [, setSelectedCoordId, ] = useSessionStorage('selectedCoordId', "")

  const [currentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})

  const [allLabels, setAllLabels] = useLocalStorage<StationLabelStore>("stationLabelStore", {});

  const [stationLabels, setStationLabels] = useState(allLabels[currentBorehole.project]?.[currentBorehole.borehole] || {});
  const [showLabelsOnly, setShowLabelsOnly] = useState(false)

  const [isHovered, setIsHovered] = useState<string>("");

  // What's being selected and the labels to be exported
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])

  // List of ids that only includes the filtered coordinates
  const [filteredIds, setFilteredIds] = useState<string[]>([])

  const [hideAll, setHideAll] = useState(false)
  const [filter, setFilter] = useState<Filter | undefined>()

  useEffect(() => {
    setStationLabels(allLabels[currentBorehole.project]?.[currentBorehole.borehole] || {})
  }, [currentBorehole, allLabels])
  
  function bulkChangeVisibility(hide: boolean) {
    setStationLabels(prev => {
      const newState = {...prev}
      Object.values(newState).forEach(station => {
        station.labels.forEach(label => {
          label.coordinates.forEach((coord) => {
            if (filteredIds.includes(coord.id)) {
              coord.hide = hide;
            }
          })
        })
      });
      setAllLabels((prev) => {
        return saveLabelsToStorage(currentBorehole.project, currentBorehole.borehole, prev, newState)
      })

      return newState
    })
  }

  const updateVisibility = (path: any[], hide: boolean) => {
    setStationLabels(prev => {
      const newState = { ...prev };

      let current: any = newState;

      path.forEach((key, index) => {
        if (index === path.length - 1) {
          if (current[key].coordinates?.length > 0) {
            current[key].coordinates.forEach((c: any) => {
              c.hide = hide;
            })
          } else {
            current[key].hide = hide;
          }
        } else {
          current = current[key];
        }
      });
      setAllLabels((prev) => {
        return saveLabelsToStorage(currentBorehole.project, currentBorehole.borehole, prev, newState)
      })

      return newState;
    });
  };

  const updateSelectedLabels = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedLabels(prev => [...prev, id])
    } else {
      setSelectedLabels(prev => {
        const newState = prev.filter(label => label !== id);
        return newState;
      });
    }
  }

  const handleCoordClick = (stationKey: string, id: string) => {
    setCenterStationId(stationLabels[stationKey].stationId);
  }

  const handleEditClick = (stationKey: string, labelIndex: number, id: string) => {
    handleCoordClick(stationKey, id)

    setEditLabel({stationKey, labelIndex});
    setModalVisibility(true);

    setModalPos({ top: 100, left: 240});
  }

  const checkboxPoint = (stationKey: string, labelIndex: number) => {
    const label = stationLabels[stationKey].labels[labelIndex]
    const coord = label.coordinates[0]
    const id = coord.id

    if (!filteredIds.includes(id)) return null;

    const isSelected = selectedLabels.includes(id);

    const checkbox = (
      <div className={styles.checkboxContainer}>
        <Checkbox
          size="small"
          checked={isSelected}
          onChange={(e, checked) => {
            updateSelectedLabels(id, checked)
          }}
          sx={boxStyle} />
        <span
          onClick={() => handleCoordClick(stationKey, id)}
          className={`noselect ${styles.label}`}>
          {stationLabels[stationKey].labels[labelIndex].name}
        </span>
      </div>
    )


    return checkboxRow(checkbox, [stationKey, "labels", labelIndex, "coordinates", 0])

  }

  const checkboxHorizon = (stationKey: string, labelIndex: number, allCoordsChecked: boolean) => {
    const horizon = stationLabels[stationKey].labels[labelIndex];

    const handleHorizonChange = (checked: boolean) => {
      horizon.coordinates.forEach((coord) => {
        updateSelectedLabels(coord.id, checked)
      })
    };
    const checkbox = (
      <div className={styles.checkboxContainer}>
        <Checkbox
          size="small"
          checked={allCoordsChecked}
          onChange={(e, checked) => handleHorizonChange(checked)}
          sx={boxStyle} />
        <span
          className={`noselect ${styles.label}`}
          onClick={() => handleCoordClick(stationKey, horizon.id)}>
            {horizon.name}
        </span>
      </div>
    )

    // if there is at least one coordinate that is visible, show visible icon
    const hideAll = horizon.coordinates.some(c => c.hide);

    return checkboxRow(checkbox, [stationKey, "labels", labelIndex], hideAll)

  };

  const horizonLabels = (stationKey: string, labelIndex: number) => {
    const horizons = () => {
      const label = stationLabels[stationKey].labels[labelIndex]
      if (label.coordinates.length < 2) return null;

      let checkAll = true;
      const coords = label.coordinates.map((coord, coordIndex) => {
        const label = CoordToString(coord);

        const id = coord.id;

        if (!filteredIds.includes(id)) {
          return null;
        }

        const isSelected = selectedLabels.includes(id);
        checkAll = isSelected && checkAll

        const checkbox = 
          <div className={`${styles.checkboxContainer} ${styles.horizonCoord}`}>
            <Checkbox
              size="small"
              checked={isSelected}
              onChange={(e, checked) => {
                updateSelectedLabels(id, checked)
              }}
              sx={boxStyle} />
            <span
              className={`noselect ${styles.label}`}
              onClick={() => handleCoordClick(stationKey, id)}
            >
              {label}
            </span>
          </div>

        return checkboxRow(checkbox, [stationKey, "labels", labelIndex, "coordinates", coordIndex])

      }).filter(Boolean);

      const coordsAreAllNull = coords.length === 0;
      if (coordsAreAllNull) {
        return null;
      } else {
        return (
          <div key={crypto.randomUUID()}>
            {checkboxHorizon(stationKey, labelIndex, checkAll)}
            {coords}
          </div>
        )
      }
    };
    return horizons();
  }

  // single point
  const checkboxRow = (formControLabel: React.ReactNode, pathToCoord: any[], hideAll?: boolean) => {
    let obj: any = stationLabels;
    pathToCoord.forEach(path => {
      obj = obj[path]
    });

    const coord: Coordinates = obj

    let editing = false;
    if (editLabel) {
      const editingId = stationLabels[editLabel?.stationKey].labels[editLabel?.labelIndex].id
      editing = editingId === stationLabels[pathToCoord[0]].labels[pathToCoord[2]].id
    }

    return (
      <div
        key={crypto.randomUUID()}
        className={styles.rowContainer}
        onMouseEnter={() => {
          setSelectedCoordId(coord.id);
          setIsHovered(coord.id);
        }}
        style={{opacity: editing ? 0.5 : 1}}
        onMouseLeave={() => {
          setSelectedCoordId("")
          setIsHovered("")
        }}>

        {formControLabel}

        {isHovered && coord.id === isHovered && (
          <>
            <IconButton sx={{ width: "24px", height: "24px" }}
              aria-label="edit label" 
              onClick={() => handleEditClick(pathToCoord[0], pathToCoord[2], coord.id)} >
              <img src={Edit} />
            </IconButton>
            <IconButton sx={{ width: "24px", height: "24px", padding: "4px", justifyContent: "center" }}
              aria-label="hide label"
              onClick={() => {
                updateVisibility(pathToCoord, (hideAll == null ? !coord.hide : !hideAll))
              }}
            >
              {
                (coord.hide || (hideAll != null && hideAll)) ?
                  <Invisible size={24} color="var(--darker-text)" />
                  :
                  <Visible size={24} color="var(--darker-text)" />
              }
            </IconButton>
          </>
        )}
      </div>
    )
  }

  const selectAllCheckbox = () => {
    return (
      <div
        className={styles.rowContainer}
        onMouseEnter={() => {
          setSelectedCoordId("")
          setIsHovered("SelectAll")
        }}
        onMouseLeave={() => setIsHovered("")}>

        <div className={`${styles.checkboxContainer}`}>
          <Checkbox
            size="small"
            onChange={(e, checked) => {
              filteredIds.forEach(id => {
                updateSelectedLabels(id, checked)
              });
            }}
            sx={boxStyle} />
          <span className={`noselect ${styles.label}  ${styles.bold}`}>Select All</span>
        </div>

        {
          isHovered === "SelectAll" &&
          <IconButton sx={{ width: "24px", height: "24px", padding: "4px", justifyContent: "center" }}
            aria-label="hide label"
            onClick={() => {
              bulkChangeVisibility(!hideAll)
              setHideAll((prev) => !prev)
            }}
          >
            {
              hideAll ?
                <Invisible size={24} color="var(--darker-text)" />
                :
                <Visible size={24} color="var(--darker-text)" />
            }
          </IconButton>
        }

      </div>
    )
  }

  const labelList = () => {
    const keys = Object.keys(stationLabels);
    return keys.map((key: string) => {
      const stationData = stationLabels[key];
      const checkboxRows = stationData.labels.map((label, i) => {
        if (label.coordinates.length === 1) {
          return checkboxPoint(key, i)
        } else {
          return showLabelsOnly ? <></> : horizonLabels(key, i)
        }
      })

      return (
        <div key={crypto.randomUUID()}>
          {checkboxRows}
        </ div>
      );
    });
  };

  useEffect(() => {
    applyFilter(filter)

  }, [stationLabels])

  function shouldAddToList(label: Label, filter: Filter) {
    // if no filter, just add the label
    if (filter == null) {
      return true;
    }

    let validType = false;

    // If no types selected, accept everything.
    validType = filter.types.length === 0

    // there is a type filter, and it's the right type
    if (!validType && filter.types.length > 0 && filter.types.includes(label.type)) {
      validType = true;
    }

    let validNumCoord = false
    if (!filter.labelOnly || label.coordinates.length === 1) {
      validNumCoord = true
    }

    let validAzimuth = false
    // check if the label is within the azimuth filter
    // if at least one point in a horizon is included, return true
    label.coordinates.forEach(c => {
      if (pointIsInRange(c.azimuth, filter.azimuth)) {
        validAzimuth = true;
      }
    });

    let validDepth = false
    label.coordinates.forEach(c => {
      if (pointIsInRange(c.depth, filter.depth)) {
        validDepth = true;
      }
    });

    return validType && validNumCoord && validAzimuth && validDepth

  }

  const pointIsInRange = (value: number, range: number[]) => {
    const [min, max] = range;

    // Check if the range wraps around
    if (min > max) {
      return value >= min || value <= max;
    }

    // Normal range check
    return value >= min && value <= max;
  }

  const applyFilter = (filter: Filter | undefined) => {
    const ids: string[] = [];

    Object.values(stationLabels).forEach(station => {
      station.labels.forEach(label => {
        if (filter == null || shouldAddToList(label, filter)) {
          ids.push(...label.coordinates.map((c) => c.id));
        }
      });
    });
    setFilteredIds(ids)
  }


  const getExportObj = () => {
    const result: any[] = [];
    const project = currentBorehole.project
    const borehole = currentBorehole.borehole

    Object.values(stationLabels).forEach(station => {
      result.push(...station.labels.flatMap((label) =>
        label.coordinates
          .filter((coordinate) => selectedLabels.includes(coordinate.id))
          .map((coordinate) => ({
            name: label.name,
            type: label.type,
            x: coordinate.x,
            y: coordinate.y,
            depth: coordinate.depth,
            azimuth: coordinate.azimuth,
            distance: coordinate.y * 500,
            project: project,
            borehole: borehole
          }))
      ));
    });

    return result
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <span className={styles.title}>Labels</span>
        <FilterComponent
          onClickShowResult={(filter) => {
            setFilter(filter)
            applyFilter(filter)
          }} />

        {/* <Toggle
          text={"View only labels"}
          checked={showLabelsOnly}
          onChange={(checked) => {
            setShowLabelsOnly(checked);
            handleShowLabelsOnly(checked);
          }}
          labelPlacement={"end"}
        /> */}

        <FormGroup>
          {selectAllCheckbox()}
          {labelList()}
        </FormGroup>
      </div>
      <div className={styles.exportContainer}>
        <CsvDownloadButton
          className={`${styles.exportButton} ${selectedLabels.length === 0 && styles.disabled}`}
          data={getExportObj()}
          >
          Export
        </CsvDownloadButton>
       
      </div>
    </div>
  );
}