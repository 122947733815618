import { Coordinates } from "models/Coordinates"

export const CoordToString = (coord: Coordinates) => {
  const label = `${Math.round(coord.y * 500)}cm, ${coord.azimuth}°, ${coord.depth}`

  return label
}

export const CoordToDepth = (coord: Coordinates) => {
  const label = `${Math.round(coord.y * 500)}cm`

  return label
}