import { createContext, useContext, useState } from 'react';

// Create a context for visibility settings
const ImageVisibilityContext = createContext({
  syncAtaVisibility: false,
  syncCoreSampleVisibility: false,
  globalAtaVisibility: false,
  setGlobalAtaVisibility: (value: boolean) => {},
  globalCoreSampleVisibility: false,
  setGlobalCoreSampleVisibility: (value: boolean) => {},
  onSyncCoreSampleVisibilityChanged: (value: boolean) => {},
  onSyncAtaVisibilityChanged: (value: boolean) => {},
});
export const ImageVisibilityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [syncAtaVisibility, setSyncAtaVisibility] = useState<boolean>(false);
  const [syncCoreSampleVisibility, setSyncCoreSampleVisibility] = useState<boolean>(false);
  const [globalAtaVisibility, setGlobalAtaVisibility] = useState<boolean>(true);
  const [globalCoreSampleVisibility, setGlobalCoreSampleVisibility] = useState<boolean>(true);


  const onSyncCoreSampleVisibilityChanged = (flag: boolean) => {
    setSyncCoreSampleVisibility(flag)
    
    if (flag) {
      setGlobalCoreSampleVisibility(true)
    }
  }

  const onSyncAtaVisibilityChanged = (flag: boolean) => {
    setSyncAtaVisibility(flag)
    
    if (flag) {
      setGlobalAtaVisibility(true)
    }
  }

  return (
    <ImageVisibilityContext.Provider value={{
      syncAtaVisibility,
      syncCoreSampleVisibility,
      globalAtaVisibility,
      setGlobalAtaVisibility,
      globalCoreSampleVisibility,
      setGlobalCoreSampleVisibility,
      onSyncAtaVisibilityChanged,
      onSyncCoreSampleVisibilityChanged,
    }}>
      {children}
    </ImageVisibilityContext.Provider>
  );

  
}

export const useImageVisibility = () => {
  return useContext(ImageVisibilityContext);
};
