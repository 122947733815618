

export const UnknownIcon = ({cx, cy, highlight} : {cx: number, cy: number, highlight: boolean}) => {
  const scale = highlight ? 1.3 : 1

  return (
    <svg x={cx - 3 * scale} y={cy - 3 * scale} width={6 * scale} height={6 * scale} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect stroke="white" strokeWidth={highlight ? 1 : 0} width="10" height="10" rx="2" fill="#F7C368"/>
    </svg>

  )
}