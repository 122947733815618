import { Checkbox, FormControlLabel, Slider } from "@mui/material"
import { useEffect, useState } from "react"
import FilterIcon from "images/Filter.svg"
import styles from "./FilterComponent.module.css"
import { checkboxLabelStyle, checkboxStyle } from "styles/MuiSx"
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { StationLabelStore } from "models/StationLabelStore"
import { Filter } from "components/types/Filter"
import { LabelIcon, LabelType } from "components/types/LabelType"
import { LabelTypeDropdown } from "components/LabelTypeDropdown/LabelTypeDropdown"
import Close from "images/Close.svg"
import { Toggle } from "components/Toggle/Toggle"
import { Project } from "hooks/useProjectData"

interface FilterComponentProps {
  onClickShowResult: (filter: Filter) => void;
}

export const FilterComponent = (props: FilterComponentProps) => {
  const [maxDepth, setMaxDepth] = useState(0)
  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [showLabelsOnly, setShowLabelsOnly] = useState(false)

  const [depth, setDepth] = useState({ min: 0, max: maxDepth })
  const [azimuth, setAzimuth] = useState({ min: 0, max: 360 })
  const [invertAzimuthRange, setInvertAzimuthRange] = useState(false)

  let defaultFilter: Filter = {
    azimuth: [0, 360],
    depth: [0, maxDepth],
    types: [],
    labelOnly: false
  }
  const [checkedFootwall, setCheckedFootwall] = useState(true)

  const [selectedType, setSelectedType] = useState<LabelType|undefined>()
  const [project] = useSessionStorage<Project>('project-sokoman', {})
  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})

  useEffect(() => {
      const depths = project[currentBorehole.project]?.boreholes[currentBorehole.borehole]?.depths
      if (depths == null) return
      const depthsKeys = Object.keys(depths)
      const _maxDepth = parseInt(depthsKeys[depthsKeys.length - 1])
      setMaxDepth(_maxDepth)
  
      const newDefault = {...defaultFilter}
      newDefault.depth = [0, _maxDepth]
      defaultFilter = newDefault
      setDepth({min: depth.min, max: _maxDepth})
   
  }, [])


  const FilterHeader = () => {
    return (
      <div className={styles.filterHeaderContainer}
        onClick={() => setShowFilterMenu(prev => !prev)}
      >
        <span className={styles.filterTitle}>Filter</span>
        <img className={styles.filterIcon}
          src={FilterIcon}
        />
      </div>

    )
  }

  const handleAzimuthChange = (event: Event, value: number | number[], activeThumb: number) => {
    if (Array.isArray(value)) {
      setAzimuth({ min: value[0], max: value[1] })
    }
  }

  const invertRange = () => {
    setInvertAzimuthRange(prev => !prev)
  }

  const handleDepthChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setDepth({ min: value[0], max: value[1] })
    }
  }

  const DepthSelection = () => {
    return (
      <div className={styles.optionContainer}>
        <span className={styles.optionTitle}>Depth</span>
        <div className={styles.sliderContainer}>
          <Slider
            min={0}
            max={maxDepth}
            value={[depth.min, depth.max]}
            onChange={(e, value, i) => handleDepthChange(value)}
            valueLabelDisplay="off"
            step={1}
            disableSwap
            className="custom-slider"
          />
        </div>

        <span
          className={styles.sliderValue}
        >
          {depth.min / 100}m - {depth.max / 100 }m
        </span>
      </div>
    )
  }

  const AzimuthSelection = () => {
    return (
      <div className={styles.optionContainer}>
        <span className={styles.optionTitle}>Azimuth</span>
        <div className={styles.sliderContainer}>

          <Slider
            min={0}
            max={360}
            value={[azimuth.min, azimuth.max]}
            onChange={handleAzimuthChange}
            valueLabelDisplay="off"
            step={1}
            track={invertAzimuthRange ? "inverted" : "normal"}
            disableSwap
            className="custom-slider"
          />
        </div>

        <span  className={styles.sliderValue} >
          {azimuthRangeToString()}
        </span>

        <FormControlLabel
          sx={checkboxLabelStyle}
          control={<Checkbox
            sx={checkboxStyle} />}
          label={"Invert range"}
          onChange={invertRange}
          checked={invertAzimuthRange}
          className={styles.checkboxLabel}
        />

      </div>
    )
  }

  const TypeSelection = () => {
    return (
      <div className={styles.optionContainer}>
        <span className={styles.optionTitle}>Type</span>
        <div className="gap-column-sm"/>
        <LabelTypeDropdown 
          onOptionSelect={setSelectedType}
          selectedType={selectedType}
        />
        <div className="gap-column-sm"/>
         <Toggle
          classNameOverride={styles.labelOnlyToggle}
          text={"View only labels"}
          checked={showLabelsOnly}
          onChange={(checked) => {
            setShowLabelsOnly(checked);
          }}
          labelPlacement={"end"}
        />
       
      </div>
    )
  }

  const getFilterObj = () => {
    const filter: Filter = {
      azimuth: invertAzimuthRange ? [azimuth.max, azimuth.min] : [azimuth.min, azimuth.max],
      depth: [depth.min, depth.max],
      types: selectedType == null ? [] : [selectedType],
      labelOnly: showLabelsOnly
    }

    return filter;
  }

  const azimuthRangeToString = () => {
    return `${invertAzimuthRange ? azimuth.max : azimuth.min}° - ${invertAzimuthRange ? azimuth.min : azimuth.max}°`
  }

  const resetAzimuth = () => {
    setInvertAzimuthRange(false);
    setAzimuth({min: 0, max: 360});

    const updatedObj = getFilterObj();
    updatedObj.azimuth = defaultFilter.azimuth;
    props.onClickShowResult(updatedObj)
  }

  const resetDepth = () => {
    setDepth({min: 0, max: maxDepth});

    const updatedObj = getFilterObj();
    updatedObj.depth = defaultFilter.depth;
    props.onClickShowResult(updatedObj)
  }

  const resetSelectedType = () => {
    setSelectedType(undefined);
    const updatedObj = getFilterObj();
    updatedObj.types = defaultFilter.types
    props.onClickShowResult(updatedObj)
  }

  const resetFilter = () => {
    setSelectedType(undefined)
    setDepth({min: defaultFilter.depth[0], max:  defaultFilter.depth[1]})
    setAzimuth({min: defaultFilter.azimuth[0], max:  defaultFilter.azimuth[1]})
    setInvertAzimuthRange(false)
    setShowLabelsOnly(false)

    props.onClickShowResult(defaultFilter)
  }

  const resetLabelOnly = () => {
    setShowLabelsOnly(false);
    const updatedObj = getFilterObj();
    updatedObj.labelOnly = false;
    props.onClickShowResult(updatedObj);
  }
  const handleShowResult = () => {
    setShowFilterMenu(false)
    props.onClickShowResult(getFilterObj())
  }

  const currentFilters = () => {
    const filterButton = (text: string, onClick: () => void) => {
      return (
        <button key={`filter-${text}`} className={styles.removeFilterButton}
          onClick={onClick}>
          {text}
          <img src={Close} />
        </button>
      )
    }
    
    const buttons = []

    if (selectedType != null) {
      buttons.push({text: LabelIcon[selectedType].name, onClick: resetSelectedType})
    }
    if (invertAzimuthRange || azimuth.min !== 0 || azimuth.max !== 360) {
      buttons.push({text: azimuthRangeToString(), onClick: resetAzimuth})
    }

    if (depth.min !== 0 || depth.max !== maxDepth) {
      buttons.push({text: `${depth.min}cm - ${depth.max}cm`, onClick: resetDepth})
    }

    if (showLabelsOnly) {
      buttons.push({text: "Labels only", onClick: resetLabelOnly})
    }
    if (buttons.length === 0) {
      return null;
    } else {
      return (
        <div className={styles.cuurentFilterList}>
          <div className={styles.listContainer}>
            {
              buttons.map((button) => (
                filterButton(button.text, button.onClick)
              ))
            }
          </div>
          <button
            onClick={resetFilter}
            className={styles.clearButton}>
              Clear
          </button>
        </div>
      )
    }
    
  }

  return (
    <div className={styles.container}>
      {FilterHeader()}

      {
        showFilterMenu ?
        <>
          {DepthSelection()}

          {AzimuthSelection()}

          {TypeSelection()}

          <button
            className={styles.showResult}
            onClick={handleShowResult}>
            Show results
          </button>
        </>
        : currentFilters()
      }
    </div>
  )

}