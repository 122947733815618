import { RevolutionPicker } from "components/RevolutionPicker/RevolutionPicker"
import styles from "./StationPanel.module.css"
import bookmark from 'images/Bookmark.svg'
import { useEffect, useState } from "react";
import { Revolution } from "components/types/Revolution";
import { RevolutionPickerDropdown } from "components/RevolutionPicker/RevolutionPickerDropdown";
import { useLocalStorage, useSessionStorage } from "usehooks-ts";
import { Project } from "hooks/useProjectData";

interface StationPanelTitleProps {
  depth: number;
  panelId: number;
  revolution: Revolution;
  options: string[];
  onChangeRevolution: (revolution: Revolution) => void;
}
interface FieldNoteRead {
  [key: string]: {
    [key: string]: string[]
  }
}

export const StationPanelTitle = (props: StationPanelTitleProps) => {
  // const notRead = true;
  const [showTooltip, setShowTooltip] = useState(false);
  const [project] = useSessionStorage<Project>('project-sokoman', {})

  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})

  const [fieldNoteRead, setFieldNoteRead] = useLocalStorage<FieldNoteRead>("fieldNoteRead", {});

  const fieldNote = project[currentBorehole.project]?.boreholes[currentBorehole.borehole]?.depths[props.depth]?.["field note"] || ""
  
  return (
    <div className={styles.titleBg}>
      <div className={styles.titleRow}>
        <span className={styles.titleLabel}>Station {props.panelId + 1}: {props.depth}</span>
        {
          fieldNote != null && fieldNote.length > 0 &&
          <div className={styles.bookmarkContainer}
          onClick={(e) => {
            e.stopPropagation()
            setShowTooltip((prev) => !prev)

            let readNote: FieldNoteRead

            if (fieldNoteRead[currentBorehole.project] == null || fieldNoteRead[currentBorehole.project][currentBorehole.borehole] == null) {
              readNote = {
                [currentBorehole.project]: {
                  [currentBorehole.borehole]: [`${props.depth}`]
                }
              }
            } else {
              readNote = {
                [currentBorehole.project]: {
                  [currentBorehole.borehole]: [...fieldNoteRead[currentBorehole.project][currentBorehole.borehole], `${props.depth}`]
                }
              }
            }

            setFieldNoteRead(readNote)
          }}>
          <img src={bookmark}
          />
          {fieldNoteRead[currentBorehole.project] == null || fieldNoteRead[currentBorehole.project][currentBorehole.borehole] == null || !(fieldNoteRead[currentBorehole.project]?.[currentBorehole.borehole].includes(`${props.depth}`)) 
          ?  <div className={styles.redDot}  /> : null}
           {/* <div className={styles.redDot}  /> */}
          {
            showTooltip && 
            <div className={styles.noteTooltip}>{fieldNote}</div>
          }

        </div>
        }
        
       
      </div>
      <div className='flex flex-row center-vertical'>
        <span className={styles.revolutionLabel}>REVOLUTION</span>
        {/* <RevolutionPickerDropdown 
          options={props.options}
          valueText={`${[props.revolution.runId]} - ${props.revolution.revId}`}
          onChangeValue={props.onChangeRevolution}
        
        /> */}
        <RevolutionPicker
          options={props.options}
          selectedIndex={props.options.indexOf(`${[props.revolution.runId]} - ${props.revolution.revId}`)}
          valueText={`${[props.revolution.runId]} - ${props.revolution.revId}`}
          onChangeValue={(i) => {
            const revParsed = props.options[i].split(" - ")
            props.onChangeRevolution({runId: revParsed[0], revId: revParsed[1]})
          }} 
        />
      </div>
    </div>
  )
}