import React, { useEffect, useState } from 'react';

import { FormGroup } from "@mui/material";
import { StationPanel, StationPanelProps } from "components/StationPanel/StationPanel";
import styles from "./ComparisonMode.module.css"
import { Toggle } from "components/Toggle/Toggle";
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import RevolutionIcon from "images/Revolution.svg"
import Station from "images/Station.svg"
import { Revolution } from "components/types/Revolution";
import { RevolutionList } from "utils/RevolutionList";
import { Project } from "hooks/useProjectData";
import { useSessionStorage } from "usehooks-ts";

interface ComparisonModeProps {
  dataIds: StationPanelProps[];
  onExitPress: () => void;
  addSecondPanel: (panelId: number, revolution?: Revolution) => void
}

export const ComparisonMode = (props: ComparisonModeProps) => {
  const { dataIds, onExitPress } = props

  const [colorScheme, setColorScheme] = useState<"monochrome" | "colored">("colored")
  const [showLabels, setShowLabels] = useState(true)
  const [showHorizons, setShowHorizons] = useState(true)

  const [revolutionOptions, setRevolutionOptions] = useState<string[]>([])
  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', { project: "", borehole: "" })
  const [project] = useSessionStorage<Project>('project-sokoman', {})
  const [depthOptions, setDepthOptions] = useState<string[]>([])

  useEffect(() => {
    const runs = project[currentBorehole.project].boreholes[currentBorehole.borehole].depths[props.dataIds[0].depth].runs;
    setRevolutionOptions(RevolutionList(runs))

    const depthOptions = Object.keys(project[currentBorehole.project].boreholes[currentBorehole.borehole].depths)
    setDepthOptions(depthOptions)

  }, [currentBorehole])

  const switchControls = () => {
    return (
      <FormGroup className={styles.formGroup}>
        <Toggle
          text={"Color scheme"}
          checked={colorScheme === "colored"}
          onChange={(checked) => setColorScheme(checked ? "colored" : "monochrome")}
          labelPlacement={"start"}
        />

        <Toggle
          text={"Show labels"}
          checked={showLabels}
          onChange={(checked) => setShowLabels(checked)}
          labelPlacement={"start"}
        />
        <Toggle
          text={"Show horizons"}
          checked={showHorizons}
          onChange={(checked) => setShowHorizons(checked)}
          labelPlacement={"start"}
        />

      </FormGroup>
    )
  }

  const handleSelectStation = (panelIndex: number) => {
    if (panelIndex >= 0) {
      props.addSecondPanel(panelIndex)

    }
  }

  const handleSelectRevolution = (index: number) => {
    const revolutionStr = revolutionOptions[index]
    const revData = revolutionStr.split(" - ")
    props.addSecondPanel(dataIds[0].panelId, { runId: revData[0], revId: revData[1] })
  }

  const stationPanelSelector = () => {
    return (
      <div className={`${styles.panelSelectionContainer}`}>

        <div className={styles.panelSelectionCard}>
          <img src={RevolutionIcon} />
          <span className={styles.selectCardTitle}>Compare with another revolution</span>

          <div className={styles.border} />

          <div className={styles.panelSelectionBottom}>
            <div className={styles.panelSelectionSub}>
              <span className={styles.panelSelectionSubTitle}>Current Revolution</span>
              <span className={styles.panelSelectionSubValue}>{dataIds[0].revolution.runId} - {dataIds[0].revolution.revId}</span>
            </div>

            <div className={styles.panelSelectionSub}>
              <span className={styles.panelSelectionSubTitle}>Compare with</span>
              <DropdownMenu
                onOptionSelect={(i) => handleSelectRevolution(i)}
                label={"Select Revolution"}
                options={revolutionOptions} />
            </div>
          </div>


        </div>

        <div className={styles.panelSelectionCard}>
          <img src={Station} />
          <span className={styles.selectCardTitle}>Compare with another station</span>

          <div className={styles.border} />

          <div className={styles.panelSelectionBottom}>
            <div className={styles.panelSelectionSub}>
              <span className={styles.panelSelectionSubTitle}>Current Station</span>
              <span className={styles.panelSelectionSubValue}>Station {dataIds[0].panelId + 1}: {dataIds[0].depth}</span>
            </div>

            <div className={styles.panelSelectionSub}>
              <span className={styles.panelSelectionSubTitle}>Compare with</span>
              <DropdownMenu
                onOptionSelect={handleSelectStation}
                label={"Select Depth"}
                options={depthOptions} />
            </div>
          </div>
        </div>

      </div>
    )
  }

  return (
    <div className={styles.container} >
      <div className={styles.stationPanelContainer}>
        {
          dataIds.map((data, i) => {
            return (
              <StationPanel
                key={`comparing-panel${i}-${data.panelId}`}
                {...data}
                panelId={data.panelId}
                hideHorizons={!showHorizons}
                hideLabels={!showLabels}
                colorScheme={colorScheme === "colored"}
              />
            )
          })
        }
        {
          dataIds.length < 2 &&
          stationPanelSelector()
        }
        <div className={styles.controlContainer}>
          <div className={styles.exitContainer} >
            <button
              className="primary-button"
              onClick={() => {
                onExitPress()
              }} >Exit</button>
          </div>

          {switchControls()}
        </div>
      </div>

    </div>

  )
}