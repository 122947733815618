import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

export interface Project {
  [key: string]: Boreholes;
}
interface Boreholes {
  "boreholes": Borehole,
  "name": string,
}

export interface Borehole {
  [key: string]: {
    "depths": Depths;
    "name": string;
  }
}
export interface Depths {
  [key: string]: Depth;
}

interface Depth {
  "XYZ": number[];
  "assay": string[];
  "azi": number;
  "core sample extract": string | null;
  "core sample source": string | null;
  "field note": string | null;
  "depth": string;
  "runs": Runs;
}
export interface Runs {
  [key: string]: {
    "revs": Rev
  }
}
interface Rev {
  [key: string]: {
    "ATA": string;
    "polar_raw": string;
    "polar_signal": string;
    "radar_raw": string;
    "radar_signal": string;
    "rev_id": string;
  };
}

const useProjectData = (testing: boolean) => {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});

  const prod = "https://lbl.novamerainc.com/static/manifest/bh_index.json"
  const test = "https://lbl.novamerainc.com/static/manifest/testing_bh_index.json"

  const bhManifestUrl = testing ? test : prod
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching", bhManifestUrl);
        const response = await fetch(bhManifestUrl);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        let responseText = await response.text();
        const project:Project = JSON.parse(responseText); 
        
        setProject(project)
      } catch (e: any) {
        console.error("Error fetching manifest", e)
      } finally {
        setIsLoading(false)
      }
    };

    fetchData();

  }, [testing]);
  return { isLoading, project };
};

export default useProjectData;