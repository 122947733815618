import { Breadcrumbs as MuiBreadcrumbs, Chip, emphasize, styled, } from "@mui/material"
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import { Project } from "hooks/useProjectData";
import Chevron from "images/Chevron.svg"
import RightChevron from "images/RightChevron.svg"
import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

export const Breadcrumbs = () => {
  const [currentBorehole, setCurrentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})
  const [project] = useSessionStorage<Project>('project-sokoman', {})

  const [boreholeOptions, setBoreholeOptions] = useState<string[]>([])
  const [projectOptions, setProjectOptions] = useState<string[]>([])

  useEffect(() => {
    try {
      const projectKeys = Object.keys(project)
      setProjectOptions(projectKeys)
      const defaultProject = projectKeys[0]
  
      const boreholes = Object.keys(project[defaultProject].boreholes)
      const defaultBorehole = boreholes[0]
      setBoreholeOptions(boreholes)
  
      setCurrentBorehole({project: defaultProject, borehole: defaultBorehole})
  
    } catch (e) {
      console.log(JSON.stringify(project))
    }
  
  }, [project])

  const onChangeProject = (index: number) => {
    const boreholes = Object.keys(project[projectOptions[index]].boreholes)
    const defaultBorehole = boreholes[0]
    setBoreholeOptions(boreholes)

    setCurrentBorehole({project: projectOptions[index], borehole: defaultBorehole})
  }

  const onChangeBorehole = (index: number) => {
    setCurrentBorehole({project: currentBorehole.project, borehole: boreholeOptions[index]})
  }

  return (
    <MuiBreadcrumbs aria-label="breadcrumb"
      style={{color: "var(--focused-border)"}}
      separator={<img src={RightChevron} />}>
       <DropdownMenu
          onOptionSelect={onChangeProject}
          label={currentBorehole.project}
          options={projectOptions} />
        <DropdownMenu
          onOptionSelect={onChangeBorehole}
          label={currentBorehole.borehole}
          options={boreholeOptions} />
    </MuiBreadcrumbs>
  )
}