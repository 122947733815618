import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs"
import Logo from "images/Logo.svg"
import styles from "./TopBar.module.css"

export const TopBar = () => {

  return (
    <div className={styles.container}>
      <div className={styles.breadcrumbsContainer}>
        <img src={Logo} />
        <Breadcrumbs />

      </div>

      {/* <img className={styles.userIcon} /> */}

    </div>
  )
}