import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CoordinatesOnElement } from 'utils/MouseEvents';
import { getAzimuthFromPolargram, getAzimuthFromRadargram, polargramToRadargram, radargramToPolargram } from "utils/Conversion";
import styles from './ScannedImage.module.css';
import { useMousePosition } from "context/MousePositionContext";
import { useLabelModal } from "context/LabelModalProvider";
import { Coordinates } from "models/Coordinates";
import { useLocalStorage, useSessionStorage } from "usehooks-ts";
import { StationLabelStore } from "models/StationLabelStore";
import { Arc, Line, Marker } from "./MarkerComponents";
import { DiagramType } from "components/types/DiagramType";
import { Revolution } from "components/types/Revolution";

interface ScannedImageProps {
  panelId: number;
  depth: number;
  isFocusMode: boolean;
  diagramType: DiagramType;
  diagramImage: string;
  diagramRef: React.RefObject<HTMLDivElement>;
  pointerX: number;
  pointerY: number;
  revolution: Revolution;
  isHoveringOn: (diagramType: DiagramType) => boolean;

  hideHorizons?: boolean;
  hideLabels?: boolean;

  scale: number;
  setScale: (value: number) => void;
  offset: { x: number, y: number };
  setOffset: ({ x, y }: { x: number, y: number }) => void;

}

export const ScannedImage: React.FC<ScannedImageProps> = (props: ScannedImageProps) => {
  const {
    panelId, isFocusMode, diagramType, diagramImage, diagramRef,
    pointerX, pointerY, isHoveringOn, hideHorizons, hideLabels,
    scale, setScale, offset, setOffset,
    depth, revolution
  } = props
  const isHovering = isHoveringOn(diagramType);
  const pointerRef = useRef<HTMLDivElement>(null)
  const [currentBorehole] = useSessionStorage('current-borehole', {project: "", borehole: ""})
  const [allLables] = useLocalStorage<StationLabelStore>("stationLabelStore", {});

  const [stationLabels, setStationLabels] = useState(allLables[currentBorehole.project]?.[currentBorehole.borehole] || {});
  
  const [selectedCoordId] = useSessionStorage('selectedCoordId', "")
  const [isLoading, setIsLoading] = useState(false)

  const { radargramPos, polargramPos, isPointerVisible, hoveredImage,
    setHoveredRadargramRef, setHoveredPolargramRef } = useMousePosition();
  const { setModalVisibility, setModalPos, setTempCoords, tempCoords, editingCoordIndex, setEditingCoordIndex, editLabel } = useLabelModal();

  let imageSize = diagramRef?.current?.offsetWidth;


  useEffect(() => {
    try {
      setStationLabels(allLables[currentBorehole.project]?.[currentBorehole.borehole] || {})
    } catch {

    }
  },[allLables])
  
  const handleZoom = (imageRef: any, event: React.WheelEvent<HTMLImageElement>) => {
    event.stopPropagation();

    // Determine the direction of scrolling (up or down)
    const delta = event.deltaY < 0 ? 1 : -1;

    // Calculate the new scale
    let newScale = scale + delta * 0.1; // Adjust this factor as needed
    newScale = Math.min(Math.max(newScale, 1), 3); // Limit the scale between 1 and 3

    // Get the cursor position relative to the image
    const rect = imageRef.current.getBoundingClientRect();
    const cursorX = event.clientX - rect.left;
    const cursorY = event.clientY - rect.top;

    // Gradually reduce the offset when zooming out
    if (newScale < scale) {
      // If scaling out, gradually reduce the offset
      const scaleFactor = (newScale / scale);
      setOffset({
        x: offset.x * scaleFactor,
        y: offset.y * scaleFactor,
      });
    } else {
      // If zooming in, calculate the offset to keep the cursor at the center
      const newOffsetX = (cursorX - offset.x) * (newScale / scale - 1);
      const newOffsetY = (cursorY - offset.y) * (newScale / scale - 1);

      setOffset({ x: offset.x + newOffsetX, y: offset.y + newOffsetY });
    }

    // Set the scale, ensuring it stays within the bounds
    setScale(newScale);

    // If scale reaches 1, reset the offset to (0, 0)
    if (newScale === 1) {
      setOffset({ x: 0, y: 0 });
    }
  };

  const Pointer = (x: number, y: number, diagramType: DiagramType) => {
    if (!isPointerVisible || x < 0 || y < 0 || !imageSize) return null;

    const localX = applyLocalScale(x, offset.x)
    const localY = applyLocalScale(y, offset.y)

    let azimuth = 0;
    let distance = 0
    if (diagramType === "radargram") {
      azimuth = getAzimuthFromRadargram(x, y)
      distance = y * 500
    } else {
      azimuth = getAzimuthFromPolargram(x, y)

      const convert = polargramToRadargram(1, pointerX, pointerY)
      distance = convert.radarY * 500
    }

    const mouseOnDiagram = isHoveringOn(diagramType)
    return (
      <div
        ref={pointerRef}
        style={{
          position: "absolute",
          top: 0, left: 0,
          zIndex: 1
        }}>
        {
          !mouseOnDiagram &&
          <div
            className={styles.pointer}
            style={{ left: localX, top: localY }}
          />
        }

        <div
          className={`${styles.tooltip} ${mouseOnDiagram ? styles.light : styles.dark}`}
          style={{ left: localX + 10, top: localY + 10 }}
        >
          <span>
            {azimuth}°, {Math.round(distance)}cm
          </span>
        </div>
      </div>
    )
  }

  const applyLocalScale = (value: number, offset: number) => {
    if (!imageSize) return value;

    return (value * imageSize! - offset) * scale;
  }

  const getNormalizedValue = (value: number, offset: number) => {
    if (!imageSize) return value;
    return (value / scale + offset) / imageSize;
  }

  const horizon = (c1: Coordinates, c2: Coordinates, highlight: boolean) => {
    const absoluteX1 = applyLocalScale(c1.x, offset.x);
    const absoluteY1 = applyLocalScale(c1.y, offset.y);

    const absoluteX2 = applyLocalScale(c2.x, offset.x);
    const absoluteY2 = applyLocalScale(c2.y, offset.y);

    if (absoluteX2 < absoluteX1) {
      // wrap the line
      return (
        <>
          <Line highlight={highlight} x1={absoluteX1} y1={absoluteY1} x2={window.innerWidth} y2={absoluteY2} />
          <Line highlight={highlight} x1={0} y1={absoluteY1} x2={absoluteX2} y2={absoluteY2} />
        </>
      );
    } else {
      // Normal line if the next dot is to the right
      return <Line highlight={highlight} x1={absoluteX1} y1={absoluteY1} x2={absoluteX2} y2={absoluteY2} />
    }
  }

  const tempLabels = () => {
    if (tempCoords.panelId === panelId && tempCoords.coordinates.length > 0 && diagramType === "radargram") {
      if (!imageSize) return
      return (
        <React.Fragment key={crypto.randomUUID()}>
          {tempCoords.coordinates.map((c, index) => {
            const editing = editingCoordIndex === index
            const lines = index < tempCoords.coordinates.length - 1 ? (
              (() => {
                const nextCoord = tempCoords.coordinates[index + 1];
                return horizon(c, nextCoord, false)
              })()
            ) : null;

            return (
              <React.Fragment key={crypto.randomUUID()}>
                {lines}
                <Marker
                  labelType={tempCoords.labelType ?? "unk"}
                  highlight={editing}
                  cx={applyLocalScale(c.x, offset.x)}
                  cy={applyLocalScale(c.y, offset.y)} />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  const createTempLabel = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (diagramType !== "radargram" || !imageSize || (editLabel && editingCoordIndex < 0)) return;

    const { left, top } = diagramRef.current!.getBoundingClientRect();

    const normalizedX = getNormalizedValue(event.clientX - left, offset.x)
    const normalizedY = getNormalizedValue(event.clientY - top, offset.y)

    const labelType = tempCoords.labelType ?? "unk"

    if (editingCoordIndex >= 0) {
      const newTempLabels = [...tempCoords.coordinates];
      newTempLabels[editingCoordIndex].x = normalizedX;
      newTempLabels[editingCoordIndex].y = normalizedY;
      newTempLabels[editingCoordIndex].azimuth = getAzimuthFromRadargram(normalizedX, normalizedY);

      setTempCoords({ panelId: panelId, coordinates: newTempLabels, labelType: labelType });
      setEditingCoordIndex(-1)
    } else {
      const newEntry: Coordinates = {
        x: normalizedX,
        y: normalizedY,
        id: crypto.randomUUID(),
        hide: false,
        stationId: panelId,
        azimuth: getAzimuthFromRadargram(normalizedX, normalizedY),
        depth: depth,
        runId: revolution.runId,
        revId: revolution.revId
      }

      if (event.shiftKey && tempCoords.coordinates.length > 0) {
        const newTempLabels = [...tempCoords.coordinates];
        newTempLabels.push(newEntry);
        setTempCoords({ panelId: panelId, coordinates: newTempLabels, labelType: labelType });
        return;
      } else {
        setTempCoords({ panelId: panelId, coordinates: [newEntry], labelType: labelType });

        setModalVisibility(true);
        setModalPos({ top: event.clientY, left: event.clientX });
      }
    }
  };


  const arc = (c1: { x: number; y: number }, c2: { x: number; y: number }, highlight: boolean) => {
    if (!imageSize) return;

    return (
      <Arc
        center={{ x: applyLocalScale(0.5, offset.x), y: applyLocalScale(0.5, offset.y) }}
        point1={{ x: (c1.x - offset.x) * scale, y: (c1.y - offset.y) * scale }}
        point2={{ x: (c2.x - offset.x) * scale, y: (c2.y - offset.y) * scale }}
        imageSize={imageSize}
        highlight={highlight}
      />
    );
  }

  const drawLabelsForRadargram = () => {
    const stationKey = `station${panelId}`
    return (
      <React.Fragment>

        {stationLabels[stationKey]?.labels.map((label, i) => (
          <React.Fragment key={crypto.randomUUID()}>
            {label.coordinates.map((c, j) => {
              if (c.hide || !imageSize) return null

              const isEditingLabel = editLabel?.stationKey === stationKey && editLabel?.labelIndex === i

              const isEditingCoord = isEditingLabel && editingCoordIndex === j

              const highlightMarker = selectedCoordId === c.id
              const highlightLine = selectedCoordId === label.id

              const absoluteX = c.x * imageSize!;
              const absoluteY = c.y * imageSize!;

              const lines = j < label.coordinates.length - 1 ? (
                (() => {
                  const nextCoord = label.coordinates[j + 1];
                  return horizon(c, nextCoord, highlightLine)
                })()
              ) : null;

              return (
                <svg key={crypto.randomUUID()}
                  style={{ opacity: isEditingLabel ? 0.6 : 1 }}>
                  {!(hideHorizons != null && hideHorizons) && lines}
                  {
                    !(hideLabels != null && hideLabels) &&
                    <Marker
                      labelType={label.type}
                      highlight={isEditingCoord || (!isEditingLabel && (highlightMarker || highlightLine))}
                      cx={(absoluteX - offset.x) * scale}
                      cy={(absoluteY - offset.y) * scale} />
                  }

                </svg>
              );
            })}
          </React.Fragment>
        ))}

      </React.Fragment>
    )
  }

  const drawLabelsForPolargram = () => {
    const stationKey = `station${panelId}`
    return (
      <React.Fragment>
        {stationLabels[stationKey]?.labels.map((label, i) => (

          <React.Fragment key={crypto.randomUUID()}>
            {label.coordinates.map((c, j) => {
              if (!imageSize || c.hide) return null

              const isEditingLabel = editLabel?.stationKey === stationKey && editLabel?.labelIndex === i

              const isEditingCoord = isEditingLabel && editingCoordIndex === j

              const highlightMarker = selectedCoordId === c.id
              const highlightLine = selectedCoordId === label.id

              const absoluteX = c.x * imageSize!;
              const absoluteY = c.y * imageSize!;

              const c1Polar = radargramToPolargram(imageSize, imageSize, imageSize, absoluteX, absoluteY);
              const lines = j < label.coordinates.length - 1 ? (
                (() => {
                  const nextCoord = label.coordinates[j + 1];

                  const absoluteNextX = nextCoord.x * imageSize!;
                  const absoluteNextY = nextCoord.y * imageSize!;

                  const c2Polar = radargramToPolargram(imageSize, imageSize, imageSize, absoluteNextX, absoluteNextY);

                  return arc(c1Polar, c2Polar, false)
                })()
              ) : null;

              return (
                <svg key={crypto.randomUUID()}
                  style={{ opacity: isEditingLabel ? 0.6 : 1 }}>
                  {!(hideHorizons != null && hideHorizons) && lines}
                  {
                    !(hideLabels != null && hideLabels) &&
                    <Marker
                      labelType={label.type}
                      highlight={isEditingCoord || (!isEditingLabel && (highlightMarker || highlightLine))}

                      cx={(c1Polar.x - offset.x) * scale}
                      cy={(c1Polar.y - offset.y) * scale} />
                  }

                </svg>
              );
            })}
          </React.Fragment>
        ))}

      </React.Fragment>
    )
  }

  return (
    <div style={{
      position: "relative", display: "flex",
      justifyContent: "center", alignItems: "center", width: "100%"
    }}
    >
      {Pointer(pointerX, pointerY, diagramType)}

      <div
        className={styles.imageContainer}
        style={{
          width: `var(--radargram-width${isFocusMode ? "-focus" : "-dynamic"})`,
        }}
        onMouseDown={(e) => createTempLabel(e)}
      >
        <div style={{ width: "100%", height: "100%" }} ref={diagramRef}>
          <img
            data-key={JSON.stringify({ panelId, diagramType })}
            style={{
              transform: `scale(${scale}) translate(-${offset.x}px, -${offset.y}px)`,
              transformOrigin: '0 0'
            }}
            className={`${styles.diagramImage} ${styles[`${diagramType}Image`]}`}
            src={diagramImage}
            onWheel={e => handleZoom(diagramRef, e)}
            onMouseEnter={() => {
              if (diagramType === "polargram") {
                setHoveredPolargramRef(diagramRef)
              } else {
                setHoveredRadargramRef(diagramRef)
              }
            }}
          />
        </div>
        {isHovering && !isFocusMode &&
          <div className={styles.focusedOverlay}>
            {
              scale !== 1 &&
              <span className={styles.scaleLabel}>{`${Math.round(scale * 100)}%`}</span>
            }
          </div>
        }

        <svg
          className={styles.labelCanvas}
        >
          {diagramType === "polargram" ? drawLabelsForPolargram() : drawLabelsForRadargram()}
          {tempLabels()}
        </svg>

      </div>
    </div>

  );
};