import Button from "@mui/material/Button"
import styles from "./DropdownMenu.module.css"
import {Arrow} from "images/Dynamic/Arrow"
import styled from "@emotion/styled"
import { useState } from "react"
import { Menu, MenuItem } from "@mui/material"


interface DropdownMenuProps {
  label: string,
  options: string[],
  onOptionSelect: (index: number) => void,
}
export const DropdownMenu = (props: DropdownMenuProps) => {
  const { label, options } = props

  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (event:React.MouseEvent<HTMLLIElement, MouseEvent>, index?: number) => {
    event.stopPropagation();
    setOpenMenu(false);
    setAnchorEl(null);

    if (index != null) {
      props.onOptionSelect(index);

    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenu(prev => !prev);
  };

  return (
    <>
      <Button 
        className={styles.dropdownButton}
        id="basic-button"
        onClick={handleClick}
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        endIcon={
          <div className={styles.arrowContainer}>
          <Arrow
            rotate={90}
            width={10}
            height={10}
            color={"var(--accent)"} />
          </div>
         
        }>
        {label}
      </Button>

      <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => handleClose(e)}
      
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      >
        {
          options.map((text, i) => {
            return (
              <MenuItem 
                key={`menu-${text}`}
                sx={{
                  color: "var(--default-text)",
                  '&.MuiMenuItem-root': {
                    background: "var(--header-background)",
                    "&:hover": {
                      background: "var(--content-background)"
                    }
                  }
                }}
                onClick={(e) => handleClose(e, i)}>{text}</MenuItem>
            )
          })
        }
      </Menu>
      </>
  )
}